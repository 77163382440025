import axios from "axios";
import { BACKEND_URL } from "../config/config";

const VERSION = "v1";

const getCustomSession = async (token) => {
  const response = await axios.post(
    `${BACKEND_URL}/custom-session?token=${token}`,
    {
      returnUrl: window.location.href,
    }
  );
  return response.data;
};

const getBillingPortal = async (token) => {
  const response = await axios.post(
    `${BACKEND_URL}/billing-portal?token=${token}`,
    {
      returnUrl: window.location.href,
    }
  );
  return response.data;
};

export { getBillingPortal, getCustomSession };
