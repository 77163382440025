import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Text, Img, Input, Warning } from "../../components";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { forgotUserPassword } from "store/slices/UserSlice";
import CustomInput from "components/CustomInput";
import { isValidEmail } from "utils/validations";
import { Spinner } from "components/Spinner";
import { forgotPassword } from "api/auth";
export default function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [cBtn, setCBtn] = useState(true);
  const [send, setSend] = useState(false);
  useEffect(() => {
    setCBtn(isValidEmail(email));
  }, [email]);
  const handleForgotPassword = async () => {
    if (!email) return;
    setLoading(true);
    setError("");
    try {
      const data = await forgotPassword(email);
      setSend(true);
    } catch (error) {
      console.error(error);
      setError(
        error?.response?.data?.message ||
          "Failed to forgot. Please try again later."
      );
    }
    setLoading(false);
  };
  return (
    <>
      <Helmet>
        <title>Aesthetic Locator</title>
        <meta name="description" content="Aesthetic Locator" />
      </Helmet>
      <div className="min-h-screen bg-gray-100 text-gray-900 flex justify-center items-center">
        <div className="bg-white-A700 p-10 flex flex-col items-center rounded-lg">
          {error && <Warning text={error} showImage={false} />}
          <Img
            src="logo.png"
            alt="imageseven_one"
            className="w-[50%] object-cover"
          />
          {send ? (
            <>
              <div className="flex flex-col items-center w-[68%] md:w-full gap-1">
                <Text
                  as="p"
                  className="!text-gray-600 tracking-[0.15px] text-center leading-6"
                >
                  Sent verification link to your email successfully.&nbsp;
                  Please confirm your mail inbox.
                </Text>
              </div>
              <div className="flex flex-col self-stretch items-center gap-[19px]">
                {/* <Button shape="round" className="w-full sm:px-5 tracking-[1.25px] uppercase font-medium">
                        Resend
                    </Button> */}
                <Text as="p" className="flex tracking-[0.50px]">
                  <span className="text-gray-900">
                    Go to sign in page&nbsp;
                  </span>
                  <a
                    href="sign-in"
                    className="text-teal-600 font-bold underline"
                  >
                    Sign in
                  </a>
                </Text>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col items-center w-[86%] md:w-full gap-1">
                <a href="#">
                  <Text size="lg" as="p" className="tracking-[0.18px]">
                    Forgot password?
                  </Text>
                </a>
                <Text
                  as="p"
                  className="!text-gray-600 tracking-[0.15px] text-center leading-6"
                >
                  Enter your email for the verification proccess, we will send
                  the link to your email.
                </Text>
              </div>
              <CustomInput
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mb-3"
              />
              <div className="flex flex-col self-stretch gap-4 mt-3">
                {loading ? (
                  <div className="flex justify-center">
                    <Spinner />
                  </div>
                ) : (
                  <Button
                    disabled={!cBtn}
                    variant="gradient"
                    shape="round"
                    color="teal_300_teal_600"
                    className="w-full sm:px-5 tracking-[1.25px] uppercase font-medium"
                    onClick={handleForgotPassword}
                  >
                    Continue
                  </Button>
                )}
                <Button
                  shape="round"
                  className="w-full sm:px-5 tracking-[1.25px] uppercase font-medium"
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
