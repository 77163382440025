export function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}
export function checkPasswordStrength(password, name) {
    const violatedRules =
    {
        1: password.length >= 8,
        2: /[0-9!@#$%^&*]/.test(password),
        3: !password.toLowerCase().includes(name)
    };
    let isStrong = true;
    for (const r in violatedRules) {
        if (!violatedRules[r]) {
            isStrong = false
            break;
        }
    }
    return { rules: violatedRules, isStrong }
}
