import React, { useEffect, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import { Helmet } from "react-helmet";
import { Img, Text, Button } from "../components";
import Header from "components/Header";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Spinner } from "components/Spinner";
import { toast } from "react-toastify";
import { getExports } from "api/search";
import { formatTimestamp } from "utils/utils";
import { ArrowDownTrayIcon } from "@heroicons/react/16/solid";
import { downloadRecords } from "api/record";
// import Result from "modals/Result";

export default function Results() {
  const [searchBarValue12, setSearchBarValue12] = useState("");
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userObj);
  const token = user?.token;
  const [selection, SetSelection] = useState("google-maps");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [download, setDownload] = useState(null);
  const [isResultOpen, setIsResultOpen] = useState(null);
  const [isDelOpen, setIsDelOpen] = useState(null);
  const [debouncedSearchValue] = useDebounce(searchBarValue12, 500);
  const closeResultModal = () => {
    setIsResultOpen(null);
  };

  const loadResults = async () => {
    setLoading(true);
    try {
      const dt = await getExports(token);
      setResults(dt.exports);
    } catch (error) {
      console.error("Error making request:", error);
    } finally {
      setLoading(false);
    }
  };
  const tableHeaders = ["", "Task", "Credits", "Date&Time", "Export"];

  const realResults = useMemo(() => {
    if (debouncedSearchValue.length > 0) {
      return results.filter((result) =>
        result.name.toLowerCase().includes(debouncedSearchValue.toLowerCase())
      );
    } else {
      return results;
    }
  }, [results, debouncedSearchValue]);

  const handleDownload = async (item,filetype) => {
    try {
      setDownload(item._id);
      await downloadRecords(token,filetype, item);
      toast.success("File downloaded successfully");
    } catch (err) {
      toast.error(err.response?.data?.error || "Unknown error");
      console.error(err);
    }
    setDownload(null);
  };
  useEffect(() => {
    loadResults();
  }, [token, selection]);

  const handleBackHome = () => navigate("/");
  return (
    <>
      <Helmet>
        <title>Local Business Lead List</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <div className="flex flex-col w-full gap-2 p-5 sm:p-2 bg-gray-50 h-[100vh]">
        <Header />
        <div className="w-full mx-auto  h-full">
          {loading ? (
            <Spinner />
          ) : (
            <>
              {realResults.length !== 0 ? (
                <div className="flex flex-col gap-4">
                  <div className="flex md:flex-col justify-between items-center gap-5">
                    <Text size="lg" as="p" className="tracking-[0.18px]">
                      <span className="text-black-900_01">
                        Total Results&nbsp;
                      </span>
                      <span className="text-teal-300">
                        {realResults.length}
                      </span>
                    </Text>
                  </div>
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="border max-h-[65vh] overflow-auto">
                      <table className="min-w-full divide-y divide-gray-400 dark:divide-gray-700">
                        <thead className="bg-gray-50 dark:bg-gray-800 sticky top-0">
                          <tr>
                            {tableHeaders.map((header, index) => (
                              <th
                                key={index}
                                scope="col"
                                className="font-bold px-4 py-3.5 text-md  rtl:text-right text-gray-700 dark:text-gray-400"
                              >
                                {header}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-300 dark:divide-gray-700 dark:bg-gray-900">
                          {results.map((item, index) => {
                            return (
                              <tr
                                key={index}
                                // className="hover:bg-gray-100 cursor-pointer"
                                // onClick={() => {
                                // handleShowMore(item);
                                // }}
                              >
                                <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 font-bold">
                                  {index + 1}
                                </td>
                                <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 text-center">
                                  {item.taskName}
                                </td>
                                <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 text-center">
                                  {item.creditsUsed}
                                </td>
                                <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 text-center">
                                  {formatTimestamp(item.createdAt)}
                                </td>

                                <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 text-center">
                                
                                    <div className="flex gap-2 items-center justify-center">
                                    {download && download == item._id ? (
                                    <Spinner size={8} />
                                  ) : (
                                      <>
                                      <Img
                                        src="images/csv.png"
                                        alt="CSV"
                                        className="w-10"
                                        onClick={() => handleDownload(item,"csv")}
                                        />
                                      <Img
                                        src="images/xlsx.png"
                                        alt="Excel"
                                        className="w-8"
                                        onClick={() =>handleDownload(item,"xlsx")}
                                        />
                                        </>
                                        )}
                                    </div>
                                  
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center px-14 md:p-5 bg-white-A700 rounded-[16px] h-full">
                  <div className="flex flex-col items-center w-[17%] md:w-full gap-4">
                    <Img
                      src="images/img_frame.svg"
                      alt="image"
                      className="h-[262px] w-[262px]"
                    />
                    <div className="flex flex-col items-center gap-[19px]">
                      <Text as="p" className="!text-gray-500 tracking-[0.15px]">
                        You Have no new Results at the moment.
                      </Text>
                      <Button
                        variant="gradient"
                        shape="round"
                        color="teal_300_teal_600"
                        rightIcon={
                          <Img
                            src="images/img_arrowright.svg"
                            alt="arrow_right"
                          />
                        }
                        className="gap-2 tracking-[1.25px] uppercase font-medium min-w-[142px]"
                        onClick={handleBackHome}
                      >
                        Back home
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
