import React, { useState } from "react";
import { Button, Text } from "../../components";
import { default as ModalProvider } from "react-modal";


export default function RecordDetails({
  isOpen,
  payload,
  handleClose,
  ...props
}) {


  return (
    <ModalProvider
      {...props}
      appElement={document.getElementById("root")}
      isOpen={isOpen}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center justify-center w-full max-w-lg p-8 bg-white-A700 rounded-lg">
        <Text size="lg" as="p" className="tracking-[0.18px] mb-4">
          Save Search
        </Text>
        <div className="w-full h-px bg-gray-50 mb-4" />
        <Text as="p" className="tracking-[0.15px] mb-4"></Text>
    
      </div>
    </ModalProvider>
  );
}
