import React from "react";
const sizes = {
  xs: "text-xs font-normal",
  lg: "text-2xl font-normal md:text-[22px]",
  s: "text-sm font-medium",
  md: "text-base font-normal",
};
const Text = ({ children, className = "", as, size = "md", ...restProps }) => {
  const Component = as || "p";
  return (
    <Component className={`text-gray-900 font-roboto ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};
export { Text };