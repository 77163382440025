import React from "react";
import { Img, Text } from "./..";
const Warning = ({ text, showImage = true }) => {
  return (
    <div className="flex md:flex-col self-stretch justify-center items-start w-full gap-2 p-2 mx-auto md:p-5 border-red-900 border border-solid bg-red-100 max-w-[1036px] rounded-lg">
      {showImage && <Img src="images/img_phwarning.svg" alt="phwarning_one" className="h-[24px] w-[24px] md:w-full" />}
      <Text
        as="p"
        className="w-[98%] md:w-full !text-red-900 tracking-[0.15px] text-base font-normal leading-6"
      >
        {text}
      </Text>
    </div>
  );
};
export { Warning };