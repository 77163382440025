import React from "react";
const sizes = {
  s: "text-lg font-bold",
  xs: "text-base font-bold",
};
const Heading = ({ children, className = "", size = "xs", as, ...restProps }) => {
  const Component = as || "h6";
  return (
    <Component className={`text-teal-600 font-roboto ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};
export { Heading };
