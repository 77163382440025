import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import ShowDetails from "modals/RecordDetails";
import GoogleMapWrapper from "components/GoogleMap";
import { CATEGORY_TITLE } from "config/constants";
import { Text, Button } from "components";
import { Spinner } from "components/Spinner";
const MAX_STR_LEN = 30;

function parseAddress(address) {
  // Split the address into parts based on ", "
  const addressParts = address.split(", ");

  // Extract the street address (assuming it's the first part)
  const streetAddress = addressParts[0];

  // Extract the city (assuming it's the second part)
  const city = addressParts[1];

  // Extract the ZIP code using a regex pattern
  const zipcodeMatch = address.match(/\d{5}(?:-\d{4})?/);
  const zipcode = zipcodeMatch ? zipcodeMatch[0] : "";

  // Extract the state code using a regex pattern
  const stateCodeRegex = /\b([A-Z]{2})\b/;
  const stateMatch = address.match(stateCodeRegex);
  const state = stateMatch ? stateMatch[0] : "";

  // Return the parsed components as an object
  return {
    streetAddress,
    city,
    state,
    zipcode,
  };
}

const Pagination = ({
  totalPages,
  currentPage,
  onPageChange,
  siblingCount = 1,
}) => {
  const [currentPg, setCurrentPg] = useState(currentPage);

  const range = (start, end) => {
    return [...Array(end - start + 1).keys()].map((v) => v + start);
  };

  const paginationRange = () => {
    const totalNumbers = siblingCount * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPg - siblingCount);
      const endPage = Math.min(totalPages - 1, currentPg + siblingCount);
      let pages = range(startPage, endPage);

      const hasLeftSpill = startPage > 2;
      const hasRightSpill = endPage < totalPages - 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = ["...", ...extraPages, ...pages];
          break;
        }
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, "..."];
          break;
        }
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = ["...", ...pages, "..."];
          break;
        }
      }

      return [1, ...pages];
    }

    return range(1, totalPages - 1);
  };

  const handlePageChange = (page) => {
    if (page > 5) {
      toast.warning(
        "Maximum pagination allowed till page 5. Please export to download data."
      );
    } else {
      setCurrentPg(page);
      onPageChange(page);
    }
  };

  return (
    <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
      <div className="flex items-center justify-between sm:flex-col">
        <p className="text-sm font-medium text-gray-500">
          Showing page {currentPg} of {totalPages - 1 == 0 ? 1 : totalPages - 1}
        </p>

        <div className="flex space-x-2">
          <button
            onClick={() => handlePageChange(currentPg - 1)}
            className="inline-flex items-center justify-center px-3 py-2 text-sm font-bold text-gray-400 bg-white border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 w-9 mr-3"
            disabled={currentPg === 1}
          >
            Previous
          </button>

          {paginationRange().map((page, index) =>
            page === "..." ? (
              <span
                key={index}
                className="inline-flex items-center justify-center px-3 py-2 text-sm font-bold text-gray-400 bg-white w-9"
              >
                {page}
              </span>
            ) : (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={`inline-flex items-center justify-center px-3 py-2 text-sm font-bold ${
                  currentPg === page
                    ? "text-gray-900 bg-gray-100 border-gray-900"
                    : "text-gray-400 bg-white border-gray-200"
                } rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 w-9`}
              >
                {page}
              </button>
            )
          )}

          <button
            onClick={() => handlePageChange(currentPg + 1)}
            className="inline-flex items-center justify-center px-3 py-2 text-sm font-bold text-gray-400 bg-white border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 w-9"
            disabled={currentPg === totalPages}
          >
            Next
          </button>
        </div>
        <div></div>
      </div>
    </div>
  );
};

const LimitedList = ({ items = [], handleShowMore, matchingItems = [] }) => {
  // Filter valid string items
  const validItems = items.filter(
    (item) => typeof item === "string" && item.trim().length > 0
  );

  // Sort items and prioritize matching ones (case-insensitive comparison)
  const sortedItems = [...validItems].sort((a, b) => {
    const aLower = a.toLowerCase();
    const bLower = b.toLowerCase();

    const aIsMatching = matchingItems.includes(aLower); // matchingItems is already lowercase
    const bIsMatching = matchingItems.includes(bLower);

    if (aIsMatching && !bIsMatching) return -1;
    if (!aIsMatching && bIsMatching) return 1;

    return a.length - b.length; // Sort by length if no match
  });

  // Display only the first 2 items
  const displayedItems = sortedItems.slice(0, 2);
  const hasMoreItems = sortedItems.length > 2;

  return (
    <div className="flex flex-col items-center">
      {displayedItems.map((item, index) => {
        const isMatching = matchingItems.includes(item.toLowerCase()); // Check lowercase matching
        return (
          <div
            key={index}
            className={`p-1 m-1 rounded bg-gray-200 rounded-r-lg ${
              isMatching ? "bg-[#98dcc6]" : ""
            }`}
          >
            {item}
          </div>
        );
      })}
      {hasMoreItems && (
        <button onClick={handleShowMore} className="text-blue-500 text-sm mt-1">
          Show More
        </button>
      )}
    </div>
  );
};

const DataTable = ({ data, handleShowMore, OptFilters }) => {
  const tableHeaders = [
    "Practice name",
    CATEGORY_TITLE,
    "Device",
    "Injectable",
    "Brands",
    "Products",
    "Product Category",
    "Google Reviews",
    "Google Rating",
    "Street",
    "City",
    "State",
    "Zipcode",
    "Business Tags",
  ];
  return (
    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
      <div className="border max-h-[65vh] overflow-auto">
        <table className="min-w-full divide-y divide-gray-400 dark:divide-gray-700">
          <thead className="bg-gray-50 dark:bg-gray-800 sticky top-0">
            <tr>
              {tableHeaders.map((header, index) => (
                <th
                  key={index}
                  scope="col"
                  className="px-4 py-3.5 text-sm font-normal rtl:text-right text-gray-400 dark:text-gray-400"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-300 dark:divide-gray-700 dark:bg-gray-900">
            {data.map((item, index) => {
              const { streetAddress, city, state, zipcode } = parseAddress(
                item.address
              );
              return (
                <tr
                  key={index}
                  className="hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    handleShowMore(item);
                  }}
                >
                  <td className="px-4 py-4 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">
                    {item.title.length > MAX_STR_LEN
                      ? `${item.title.substring(0, MAX_STR_LEN)}...`
                      : item.title}
                  </td>
                  <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                    <LimitedList
                      items={item.category_titles}
                      handleShowMore={() => {
                        handleShowMore(item);
                      }}
                      matchingItems={OptFilters.category_titles}
                    />
                  </td>
                  {[
                    { condition: item.device_types.includes("Devices") },
                    { condition: item.device_types.includes("Injectables") },
                  ].map((cond, index) => (
                    <td
                      key={index}
                      className="px-4 py-4 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap"
                    >
                      <div
                        className={`rounded rounded-r-lg p-1 text-center ${
                          cond.condition ? "bg-[#98dcc6]" : "bg-red-300"
                        }`}
                      >
                        {cond.condition ? "Yes" : "No"}
                      </div>
                    </td>
                  ))}
                  <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                    <LimitedList
                      items={
                        item.product_data?.brands?.map(
                          (product) => product.name
                        ) || []
                      }
                      handleShowMore={() => {
                        handleShowMore(item);
                      }}
                      matchingItems={OptFilters.brands}
                    />
                  </td>
                  <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                    <LimitedList
                      items={
                        item.product_data?.products?.map(
                          (product) => product.name
                        ) || []
                      }
                      handleShowMore={() => {
                        handleShowMore(item);
                      }}
                      matchingItems={OptFilters.products}
                    />
                  </td>
                  <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                    <LimitedList
                      items={
                        item.product_data?.categories?.map(
                          (product) => product.name
                        ) || []
                      }
                      handleShowMore={() => {
                        handleShowMore(item);
                      }}
                      matchingItems={OptFilters.categories}
                    />
                  </td>
                  <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                    {item.reviews_count}
                  </td>
                  <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                    {item.rating}
                  </td>
                  <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                    {streetAddress.length > MAX_STR_LEN
                      ? `${streetAddress.substring(0, MAX_STR_LEN)}...`
                      : streetAddress}
                  </td>
                  <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                    {city}
                  </td>
                  <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                    {state}
                  </td>
                  <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                    {zipcode}
                  </td>
                  <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                    <LimitedList
                      items={item.tags_titles}
                      handleShowMore={() => {
                        handleShowMore(item);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Data = ({
  record,
  currentPage,
  onPageChange,
  mapView,
  setMapView,
  location,
  handleMapSelect,
  radius,
  isOpen,
  handleExport,
  handleToggle,
  loading,
}) => {
  const { totalPages, data, totalItems } = record;

  const handleMapClick = (event) => {
    const loc = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    handleMapSelect(loc);
  };

  const [selectedItem, setSelectedItem] = useState(null);
  const handleShowMore = (item) => {
    setSelectedItem(item);
  };

  // TODO change icons for map and dataview
  // TODO width changes when shifting to data view
  const filters = record.filters || {};
  const OptFilters = {
    category_titles:
      filters.business?.category_titles?.map((p) => p.toLowerCase()) || [],
    products: filters.product_data?.products?.map((p) => p.toLowerCase()) || [],
    categories:
      filters.product_data?.categories?.map((c) => c.toLowerCase()) || [],
    brands: filters?.product_data?.brands?.map((b) => b.toLowerCase()) || [],
    sub_categories:
      filters?.product_data?.sub_categories?.map((sc) => sc.toLowerCase()) ||
      [],
  };
  // console.info(OptFilters)
  return (
    <section className="container px-4 mx-auto mt-2">
      <div>
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="flex justify-between sm:flex-col">
              <div className="flex md:flex-col justify-between items-center gap-5">
                {totalItems !== null && (
                  <Text size="lg" as="p" className="tracking-[0.18px]">
                    <span className="text-black-900_01">
                      Total Results&nbsp;
                    </span>
                    <span className="text-teal-300">{totalItems}</span>
                  </Text>
                )}
              </div>
              <div className="flex gap-2 flex-none md:flex-col sm:mb-2">
                <Button
                  variant="gradient"
                  className="sm:px-5 tracking-[1.25px] uppercase border border-[#cacacc] rounded-md"
                  onClick={() => {
                    handleToggle("filtersShow", !isOpen.filtersShow);
                  }}
                >
                  {isOpen.filtersShow ? "Hide" : "Show"} Filters
                </Button>
                <Button
                  variant="gradient"
                  color="teal_300_teal_600"
                  disabled={!isOpen.submit}
                  className="sm:px-5 tracking-[1.25px] uppercase font-medium rounded-md"
                  onClick={handleExport}
                >
                  Export with contact info
                </Button>
              </div>
            </div>
            <div class="pb-1 bg-white">
              <div class="flex gap-1 sm:hidden">
                <div
                  class={[
                    "inline-flex items-center text-lg  sm:w-auto sm:border-b-2 sm:py-4 rounded-t-lg p-2",
                    mapView ? "bg-teal-300 text-white-A700" : "bg-gray-200",
                  ].join(" ")}
                  onClick={() => setMapView(true)}
                >
                  <svg
                    class={[
                      "-ml-0.5 mr-2 h-5 w-5 transition-all duration-100",
                    ].join(" ")}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    />
                  </svg>
                  Map View
                </div>
                <div
                  class={[
                    "inline-flex items-center text-lg sm:w-auto sm:border-b-2 sm:py-4 group rounded-t-lg p-2",
                    !mapView ? "bg-teal-300 text-white-A700" : "bg-gray-200",
                  ].join(" ")}
                  onClick={() => setMapView(false)}
                >
                  <svg
                    class={[
                      "-ml-0.5 mr-2 h-5 w-5 transition-all duration-100",
                    ].join(" ")}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                    />
                  </svg>
                  Data View
                </div>
              </div>
              {mapView ? (
                <div className="h-[65vh]">
                  <GoogleMapWrapper
                    data={data}
                    location={location}
                    radius={radius}
                    handleMapClick={handleMapClick}
                  />
                </div>
              ) : (
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 sm:my-0">
                    <DataTable
                      data={data}
                      handleShowMore={handleShowMore}
                      OptFilters={OptFilters}
                    />
                  </div>
                </div>
              )}
            </div>
            {data.length > 0 && (
              <div className="py-3">
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={onPageChange}
                />
              </div>
            )}
          </>
        )}
      </div>

      {/* <div className="hidden sm:flex absolute top-20 left-0 bg-white-A700 h-full">
        <DataTable
          data={data}
          handleShowMore={handleShowMore}
          OptFilters={OptFilters}
        />
      </div> */}
      {selectedItem && (
        <ShowDetails
          isShowModal={selectedItem !== null}
          handleClose={() => setSelectedItem(null)}
          item={selectedItem}
          filters={OptFilters}
        />
      )}
    </section>
  );
};

export default Data;
