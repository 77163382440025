import axios from "axios";
import { BACKEND_URL } from "../config/config";

const VERSION = "v1";

const getAllRecord = async (token, page, filters = {}) => {
  try {
    const response = await axios.post(
      `${BACKEND_URL}/api/${VERSION}/records?token=${token}`,
      {
        page,
        ...filters,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching records:", error);
    throw error;
  }
};

const handleFileRequest = async (url, token, fileType, filters = {}) => {
  try {
    const response = await axios.post(
      `${BACKEND_URL}/api/${VERSION}/${url}?token=${token}`,
      { ...filters },
      {
        responseType: "blob", // Important: this tells Axios to handle binary data (Excel/CSV file)
      }
    );

    // Check if the response is a blob or an error message
    const contentType = response.headers["content-type"];

    // If the response is an error in JSON format, handle it
    if (contentType && contentType.includes("application/json")) {
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onload = () => {
          try {
            const errorData = JSON.parse(reader.result); // Parse the blob to JSON
            reject(new Error(errorData.error)); // Reject the error message so it can be handled
          } catch (e) {
            reject(new Error("Failed to parse error message from server."));
          }
        };
        reader.readAsText(response.data); // Read the blob as text to extract JSON
      });
    }

    // Determine MIME type and file extension based on fileType
    let mimeType, extension;
    if (fileType === "xlsx") {
      mimeType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      extension = "xlsx";
    } else if (fileType === "csv") {
      mimeType = "text/csv";
      extension = "csv";
    } else {
      throw new Error("Unsupported file type");
    }

    // If the response is the actual file, proceed with download
    const blob = new Blob([response.data], { type: mimeType });
    const downloadUrl = window.URL.createObjectURL(blob);
    const fileName = `${filters.taskName}.${extension}`;
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", fileName);

    // Programmatically trigger the click to start download
    link.click();

    // Clean up by revoking the URL
    window.URL.revokeObjectURL(downloadUrl);

    return; // Successful file download
  } catch (error) {
    // If it's an Axios error with a Blob response, convert it to JSON
    if (error.response && error.response.data instanceof Blob) {
      try {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onload = () => {
            try {
              const errorData = JSON.parse(reader.result); // Parse blob as JSON
              reject(new Error(errorData.error)); // Throw the server error message
            } catch (e) {
              reject(new Error("Failed to parse error message from server."));
            }
          };
          reader.readAsText(error.response.data); // Read the blob as text to extract JSON
        });
      } catch (e) {
        throw new Error("An unexpected error occurred during the request.");
      }
    } else {
      throw new Error("An unexpected error occurred during the request.");
    }
  }
};


const exportRecords = async (token, fileType, filters = {}) => {
  return handleFileRequest(`records/export`, token, fileType, filters);
};

const downloadRecords = async (token, fileType, filters = {}) => {
  return handleFileRequest(
    `records/download/${filters._id}`,
    token,
    fileType,
    filters
  );
};

export { getAllRecord, exportRecords, downloadRecords };
