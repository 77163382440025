import DeleteModal from "components/DeleteModal";
import React, { useState } from "react";

const AdminTable = ({ dataList, onEditingIndex, onDeletingIndex }) => {
  const [openModal, setOpenModal] = useState(false);
  const [index, setIndex] = useState(null);

  const handleDelete = (index) => {
    setIndex(index);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleConfirm = () => {
    setOpenModal(false);
    onDeletingIndex(index);
  };

  return (
    <section className="container px-4 mx-auto">
      <div className="flex flex-col">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="border rounded-2xl">
              <table className="min-w-full divide-y divide-gray-400 dark:divide-gray-700">
                <thead className="bg-gray-50 dark:bg-gray-800">
                  <tr>
                    {[
                      "Brand",
                      "Product Name",
                      "Sub Product",
                      "Injectable / Device",
                      "Treatment Category",
                      "Treatment Keywords",
                      "Actions",
                    ].map((text, i) => (
                      <th
                        key={i}
                        scope="col"
                        className="py-3.5 px-4 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                      >
                        {text}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-300 dark:divide-gray-700 dark:bg-gray-900">
                  {dataList && dataList.length > 0 ? (
                    dataList.map((item, index) => (
                      <tr key={index}>
                        <td className="p-4 text-sm text-center font-medium text-gray-700 dark:text-gray-200">
                          {item.brand}
                        </td>
                        <td className="p-4 text-sm text-center text-gray-500 dark:text-gray-300">
                          {item.product_name}
                        </td>
                        <td className="p-4 text-sm text-center text-gray-500 dark:text-gray-300">
                          {item?.sub_product}
                        </td>
                        <td>
                          <div
                            className={[
                              "py-1 text-sm text-center text-gray-500 dark:text-gray-300 rounded-lg",
                              item.device_type === "Devices"
                                ? "bg-[#ccf7d2]"
                                : "bg-[#b2b2dd]",
                            ].join(" ")}
                          >
                            {item.device_type}
                          </div>
                        </td>
                        <td className="p-4 text-sm text-center text-gray-500 dark:text-gray-300">
                          {item.treatment_category}
                        </td>
                        <td className="p-4 text-center text-gray-500 dark:text-gray-300 text-xs flex flex-wrap">
                          {item.treatment_keywords &&
                            item.treatment_keywords.length > 0 &&
                            item.treatment_keywords.map((key, idx) => (
                              <div
                                key={idx}
                                className="p-1 m-1 rounded bg-gray-200 rounded-r-lg"
                              >
                                {key}
                              </div>
                            ))}
                        </td>
                        <td className="p-4">
                          <div className="flex justify-around">
                            <div
                              className="p-2 rounded-full cursor-pointer hover:bg-slate-300"
                              onClick={() => onEditingIndex(item)}
                            >
                              <img
                                src="images/img_edit.svg"
                                alt="Edit"
                                className="min-w-[22px]"
                              />
                            </div>
                            <div
                              className="p-2 rounded-full cursor-pointer hover:bg-slate-300"
                              onClick={() => handleDelete(item._id)}
                            >
                              <img
                                src="images/img_trash.svg"
                                alt="Trash"
                                className="min-w-[22px]"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="p-10 text-center">
                        No results.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        openModal={openModal}
        onCloseModal={handleCloseModal}
        onConfirmModal={handleConfirm}
      />
    </section>
  );
};

export default AdminTable;
