import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Heading } from "../components";
export default function NotFound() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userObj);

  return (
    <>
      <Helmet>
        <title>Aesthetic Locator</title>
        <meta
          name="description"
          content="Aesthetic Locator"
        />
      </Helmet>
      <div className="flex flex-col w-full gap-4 p-8 sm:p-5 bg-gray-50 h-screen justify-center">
        <div className="mx-auto max-w-[1856px] flex flex-col items-center">
          <Heading as="h1" className="self-start tracking-[6.5px]">
            Page Not Found | 404
          </Heading>
          <Button
            size="xl"
            variant="fill"
            shape="circle"
            className="w-[154px] !rounded-[27px] mt-3"
            onClick={()=>navigate("/")}
          >
            Go Back
          </Button>
        </div>
      </div>
    </>
  );
}
