import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Button,
  Img,
  Text,
  Input,
  PasswordInput,
  Warning,
  Heading,
} from "../../components";
import { useNavigate } from "react-router-dom";
import CustomInput from "components/CustomInput";
import { signUp } from "api/auth";
import { checkPasswordStrength, isValidEmail } from "../../utils/validations";
import { Spinner } from "components/Spinner";
import { toast } from "react-toastify";
import { BACKEND_URL } from "config/config";
export default function SignUp() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [cBtn, setCBtn] = useState(true);
  const [pVal, setPVal] = useState({ 1: true, 2: true, 3: true });
  const [inpval, setInpval] = useState({
    name: "",
    email: "",
    password: "",
    cPassword: "",
  });

  const setVal = (e) => {
    const { name, value } = e.target;
    setInpval((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "password") {
      const { rules, isStrong } = checkPasswordStrength(
        inpval.password,
        inpval.name
      );
      setPVal(rules);
    }
    if (name === "email") isValidEmail(value);
  };
  useEffect(() => {
    setError("");
    const { rules, isStrong } = checkPasswordStrength(
      inpval.password,
      inpval.name
    );
    const con =
      inpval.name &&
      isValidEmail(inpval.email) &&
      isStrong &&
      inpval.password === inpval.cPassword;
    setCBtn(con);
  }, [inpval]);
  const handleSignup = async () => {
    const { name, email, password } = inpval; // Destructure within signUp
    setLoading(true);
    try {
      await signUp({ name, email, password });
      navigate("/verify", { state: { email: inpval.email } });
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
      } else {
        setError("Failed to register. Please try again later.");
      }
    }
    setLoading(false);
  };
  const handleGoogleLogin = async () => {
    try {
      const redirectUrl = encodeURIComponent(
        `${window.location.origin}/success`
      );
      window.location.href = `${BACKEND_URL}/auth/google?redirectUrl=${redirectUrl}`;
    } catch (error) {
      console.error("Google login error:", error);
    }
  };
  return (
    <>
      <Helmet>
        <title>Aesthetic Locator</title>
        <meta
          name="description"
          content="Aesthetic Locator"
        />
      </Helmet>
      <div className="min-h-screen bg-gray-100 text-gray-900 flex justify-center items-center">
        <div className="bg-white-A700 p-10 flex flex-col items-center rounded-lg">
          {error && <Warning text={error} showImage={false} />}
          <Img
            src="logo.png"
            alt="imageseven_one"
            className="w-[72%] object-cover"
          />

          <Text size="md" as="p" className="tracking-[0.18px]">
            Create an account
          </Text>
          <Button
            leftIcon={<Img src="images/img_icon.svg" alt="↳ 📍icon " />}
            className="w-full gap-1.5 sm:px-5 rounded-lg bg-[#dee4e1] my-4"
            onClick={handleGoogleLogin}
          >
            Continue with google
          </Button>
          <div className="flex sm:flex-col self-stretch justify-center items-center gap-4">
            <div className="sm:self-stretch h-px bg-gray-50 flex-1" />
            <Text
              as="p"
              className="self-start !text-gray-600 tracking-[0.50px]"
            >
              or sign up with e-mail
            </Text>
            <div className="sm:self-stretch h-px bg-gray-50 flex-1" />
          </div>
          <div className="w-full gap-3 flex flex-col">

          <CustomInput label="Name" name="name" type="text" placeholder="Enter your name here" onChange={setVal} value={inpval.name} />
          <CustomInput label="Email Address" name="email" placeholder="Enter your email address in here" onChange={setVal} value={inpval.email} />
          <PasswordInput onChange={setVal} name="password" value={inpval.password} />
          <PasswordInput placeholder="Enter your password again in here" label="Confirm Password" onChange={setVal} name="cPassword" value={inpval.cPassword} />
          </div>
         
          <div className="flex flex-col self-stretch items-center gap-[19px]">
            {loading ? (
              <Spinner />
            ) : (
              <Button
              variant="gradient"
              color="teal_300_teal_600"  
              className="w-full sm:px-5 tracking-[1.25px] uppercase font-medium mt-5 rounded-lg"
                onClick={handleSignup}
                disabled={!cBtn}
              >
                Continue
              </Button>
            )}
            <Text as="p" className="flex tracking-[0.50px]">
              <span className="text-gray-900">Already have an account?&nbsp;</span>
              <a href="sign-in" className="text-teal-600 font-bold underline">
                Log In
              </a>
            </Text>
          </div>
        </div>
      </div>
    </>
  );
}
