import React from "react";
import PropTypes from "prop-types";

const shapes = {
  round: "rounded-[22px]",
};

const variants = {
  fill: {
    red_50_01: "bg-red-50_01 text-red-900",
    gray_50: "bg-gray-50 text-gray-600",
  },
  outline: {
    gray_900_33: "border-gray-900_33 border border-solid text-gray-900_99",
    gray_50: "border-gray-50 border border-solid text-teal-900",
  },
  gradient: {
    teal_300_teal_600: "bg-gradient text-gray-50",
    disable: "bg-gray-200 text-gray-500", // Added gray background for disabled state
  },
};

const sizes = {
  sm: "h-[40px] px-[35px] text-base",
  xs: "h-[32px] pl-3 pr-[7px] text-xs",
  lg: "h-[48px] px-[35px] text-base",
  md: "h-[44px] px-[35px] text-sm",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  disabled = false,
  variant = "outline",
  size = "md",
  color = "gray_50",
  ...restProps
}) => {
  let appliedVariant = variant;
  let appliedColor = color;

  if (disabled) {
    appliedVariant = "gradient";
    appliedColor = "disable";
  }

  return (
    <button
      className={`${className} flex items-center justify-center text-center ${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      } ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""} ${
        (appliedVariant && variants[appliedVariant]?.[appliedColor]) || ""
      }`}
      disabled={disabled}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  shape: PropTypes.oneOf(["round"]),
  size: PropTypes.oneOf(["sm", "xs", "lg", "md"]),
  variant: PropTypes.oneOf(["fill", "outline", "gradient"]),
  color: PropTypes.oneOf([
    "red_50_01",
    "gray_50",
    "gray_900_33",
    "teal_300_teal_600",
  ]),
  disabled: PropTypes.bool,
};

export { Button };
