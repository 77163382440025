import React, { useState, useCallback, useEffect } from "react";
import { GoogleMap, Marker, Circle, InfoWindow } from "@react-google-maps/api";
import { Spinner } from "components/Spinner";
import { MarkerClusterer } from "@googlemaps/markerclusterer";


const zoom = 8;

const GoogleMapWrapper = ({ data, location, radius, handleMapClick }) => {
  const [map, setMap] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null); // Track the selected location (lat, lng)
  const [selectedTitles, setSelectedTitles] = useState([]); // Store titles for markers at the selected location
  const [markerClusterer, setMarkerClusterer] = useState(null);

  const onLoad = useCallback((map) => {
    setMap(map);

    // Create MarkerClusterer instance
    const clusterer = new MarkerClusterer({
      map,
      markers: [],
      options: {
        imagePath:
          "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
      },
    });

    setMarkerClusterer(clusterer);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
    if (markerClusterer) {
      markerClusterer.clearMarkers();
    }
  }, [markerClusterer]);

  useEffect(() => {
    if (markerClusterer && data.length > 0) {
      const markers = data.map((item) => {
        return new window.google.maps.Marker({
          position: {
            lat: parseFloat(item.latitude),
            lng: parseFloat(item.longitude),
          },
          title: item.title,
        });
      });

      markerClusterer.clearMarkers(); // Clear existing markers
      markerClusterer.addMarkers(markers); // Add new markers
    }
  }, [markerClusterer, data]);

  // Handle marker click to show InfoWindow with all titles at the clicked location
  const handleMarkerClick = (clickedMarker) => {
    const clickedLat = parseFloat(clickedMarker.latitude);
    const clickedLng = parseFloat(clickedMarker.longitude);

    // Find all markers at the same coordinates
    const markersAtSameLocation = data.filter(
      (marker) =>
        parseFloat(marker.latitude) === clickedLat &&
        parseFloat(marker.longitude) === clickedLng
    );

    // Update selected location and titles for InfoWindow
    setSelectedLocation({ lat: clickedLat, lng: clickedLng });
    setSelectedTitles(markersAtSameLocation.map((marker) => marker.title));
  };
  const centerLocation=!location.zipCode?{ lat: 40.7127753, lng: -74.0059728 }:{lat:location.lat,lng:location.lng}
  return (
    <GoogleMap
      onDblClick={handleMapClick}
      mapContainerStyle={{ width: "100%", height: "100%" }}
      center={
        data.length
          ? {
              lat:
                data.reduce((sum, item) => sum + parseFloat(item.latitude), 0) /
                data.length,
              lng:
                data.reduce(
                  (sum, item) => sum + parseFloat(item.longitude),
                  0
                ) / data.length,
            }
          : centerLocation // Fallback to default centerLocation if no data
      }
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Display Circle if markers exist */}
      {location.zipCode && radius && (
        <Circle
          center={centerLocation}
          radius={radius}
          options={{
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
          }}
        />
      )}

      {/* InfoWindow showing titles of markers at the selected location */}
      {selectedLocation && (
        <InfoWindow
          position={selectedLocation}
          onCloseClick={() => setSelectedLocation(null)}
        >
          <div>
            <h4>Markers at this location:</h4>
            <ul>
              {selectedTitles.map((title, index) => (
                <li key={index}>{title}</li>
              ))}
            </ul>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

export default GoogleMapWrapper;
