import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import Header from "./Header";
import { BACKEND_URL, STRIPE_PUBLISHABLE_KEY, STRIPE_TABLE_ID } from "config/config";
import { Spinner } from "flowbite-react";
import axios from "axios";
import { toast } from "react-toastify";
import { getCustomSession } from "api/stripe";

const StripePricingTable = () => {
  const [loading, setLoading] = useState(true);
  const [sessionId, setSessionId] = useState(null);
  const { userObj } = useSelector((state) => state.user);


  const handleManagePayment = async () => {
    try {
      const data = await getCustomSession(userObj.token);
      if (data && data.session) {
        setSessionId(data.session);
      } else {
        toast.error("Failed to retrieve session ID.");
      }
    } catch (error) {
      console.error("Error handling manage payment:", error);
    }
  };

  useEffect(() => {
    const loadStripeScript = () => {
      const script = document.createElement("script");
      script.src = "https://js.stripe.com/v3/pricing-table.js";
      script.async = true;

      script.onload = () => {
        setLoading(false);
      };

      script.onerror = () => {
        setLoading(false);
        toast.error("Failed to load Stripe script.");
      };

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    };

    const initialize = async () => {
      setLoading(true);
      await handleManagePayment();
      loadStripeScript();
    };

    if (userObj && userObj.token) {
      initialize();
    } else {
      setLoading(false);
    }
  }, [userObj]);

  return (
    <>
      <div style={{ overflowX: "auto" }}>
        <Helmet>
          <title>Aesthetic Locator</title>
          <meta name="description" content="Aesthetic Locator" />
        </Helmet>
        <div className="flex flex-col w-full gap-4 p-4 bg-gray-50 h-full">
          <Header />
          {loading ? (
            <Spinner />
          ) : sessionId ? (
            <stripe-pricing-table
              pricing-table-id={STRIPE_TABLE_ID}
              publishable-key={STRIPE_PUBLISHABLE_KEY}
              customer-session-client-secret={sessionId}
            />
          ) : (
            <p>Error loading Stripe pricing table.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default StripePricingTable;
