import React, { useState } from "react";
import { Button, PasswordInput, Text } from "../components";
import { default as ModalProvider } from "react-modal";
import { Spinner } from "components/Spinner";
import { toast } from "react-toastify";
import CustomInput from "components/CustomInput";

import { checkPasswordStrength } from "utils/validations";
import { updateUser } from "api/user";
import { setUser } from "store/slices/UserSlice";
import { useDispatch } from "react-redux";

export default function UpdateInformationModal({
  isShowModal,
  modalData,
  onModalClose,
  ...props
}) {
  const dispatch = useDispatch();
  const [inpval, setInpval] = useState({
    name: modalData.name,
    password: "",
    cPassword: "",
  });
  const [loading, setLoading] = useState(false);

  const setVal = (e) => {
    const { name, value } = e.target;
    setInpval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleUpdateUser = async () => {
    const { name, password, cPassword } = inpval;
    if (!name) {
      return toast.error("Name can't be empty");
    }
    if (!name && !password && !cPassword) {
      return toast.warn("Input missing");
    }
    if (password) {
      const { rules, isStrong } = checkPasswordStrength(
        inpval.password,
        inpval.name
      );
      console.log(rules, isStrong);
      if (!isStrong) {
        return toast.error("Passowrd not strong enought");
      }
      if (password !== cPassword) {
        return toast.error("Passwords do not match!");
      }
    }

    setLoading(true);
    try {
      const response = await updateUser(modalData.token, {
        name,
        password,
        cPassword,
      });
      if (response.name !== modalData.name) {
        dispatch(setUser({ email:modalData.email,name: response.name, token: modalData.token }));
      }
      toast.success("User updated successfully");
      onModalClose(); // Close the modal on success
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error("Failed to update user.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalProvider
      {...props}
      appElement={document.getElementById("root")}
      isOpen={isShowModal}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center justify-center w-full max-w-lg p-8 bg-white-A700 rounded-lg">
        <Text size="lg" as="p" className="tracking-[0.18px] mb-4">
          Update User
        </Text>

        <div className="w-full mb-3 gap-2 flex flex-col">
          <CustomInput
            label="Name"
            name="name"
            type="text"
            placeholder="Enter your name here"
            onChange={setVal}
            value={inpval.name}
          />
          <PasswordInput
            onChange={setVal}
            name="password"
            value={inpval.password}
            label="New Password"
          />
          <PasswordInput
            placeholder="Enter your password again in here"
            label="Confirm Password"
            onChange={setVal}
            name="cPassword"
            value={inpval.cPassword}
          />
        </div>
        <div className="flex justify-between w-full gap-4">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Button
                shape="round"
                className="tracking-[1.25px] uppercase font-medium min-w-[150px]"
                onClick={onModalClose}
              >
                Cancel
              </Button>
              <Button
                variant="gradient"
                shape="round"
                color="teal_300_teal_600"
                className="tracking-[1.25px] uppercase font-medium min-w-[150px]"
                style={{ backgroundColor: "#F44336", color: "white" }}
                onClick={handleUpdateUser}
              >
                Update
              </Button>
            </>
          )}
        </div>
      </div>
    </ModalProvider>
  );
}
