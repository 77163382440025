import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Heading, SelectBox } from "../../components";
import Header from "../../components/Header";
import { Spinner } from "components/Spinner";
import { Img, Text } from "../../components";
import MultipleSelectInput from "../../components/MultiSelectInput";
import Data from "components/Data";
import "./home.css";
import { createSearch, getSearches } from "api/search";
import CreateSearch from "modals/CreateSearch";
import { toast } from "react-toastify";
import { exportRecords, getAllRecord } from "api/record";
import { GOOGLE_MAP_KEY } from "config/config";
import {
  ArrowUpIcon,
  ChevronDoubleDownIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  MinusIcon,
  PlusIcon,
} from "@heroicons/react/16/solid";
import { useJsApiLoader } from "@react-google-maps/api";
import GoogleAutocomplete from "components/GoogleAutocomplete";
import { default as ModalProvider } from "react-modal";
import CustomInput from "components/CustomInput";
import { useSelector } from "react-redux";
import { State } from "country-state-city";
import {
  NEARBY_AREA,
  SELECTED_STATES,
  SEARCH,
  SAVED_SEARCH,
  EXPORT_LIMIT,
  RADIUS_OPTIONS,
  CATEGORY_TITLE,
} from "config/constants";
import { Checkbox } from "flowbite-react";
const OPTIONS = require("../../config/options.json");
const SERVICE_OFFERING = [
  {
    key: "products",
    label: "Product",
    placeholder: "Select Product",
    options: OPTIONS["products"],
  },
  {
    key: "brands",
    label: "Brands",
    placeholder: "Select Brand",
    options: OPTIONS["brands"],
  },
  {
    key: "categories",
    label: "Product Category",
    placeholder: "Select Product Category",
    options: OPTIONS["categories"],
  },
];
// FIXME: map select is not working

const LOCATION_TABS = [NEARBY_AREA, SELECTED_STATES];
const stateNames = State?.getStatesOfCountry("US").map((state) => state.name);
function milesToKilometers(miles) {
  return miles * 1.60934;
}
/* TODO: save search */
function ExportModal({ isShowModal, onClose, filters, total, token }) {
  const [userInput, setUserInput] = useState(
    total > EXPORT_LIMIT ? EXPORT_LIMIT : total
  ); // Input value for number of records
  const [fileLoader, setFileLoader] = useState(false);
  const [filename, setFilename] = useState("");
  const handleSubmit = async (filetype) => {
    const numRecords = parseInt(userInput, 10); // Convert input to integer
    if (!filename) {
      toast.error("Kindly choose a task name");
      return;
    }
    // Validate input
    if (isNaN(numRecords) || numRecords <= 0 || numRecords > total) {
      toast.error(
        `Please enter a number greater than 0 and less than ${total}`
      );
      return;
    }

    // All validations passed, proceed with export
    try {
      setFileLoader(true);

      // Append the user input as a max filter
      const updatedFilters = {
        ...filters,
        max: numRecords,
        taskName: filename,
      };
      // Call the export function
      await exportRecords(token, filetype, updatedFilters);

      toast.success("Records export started successfully!");

      onClose(); // Close modal after successful submission
    } catch (err) {
      // console.error(err);
      console.error("err export -> ", err);
      toast.error(err.message);
      // toast.error(err?.response?.data?.error||"Failed to export records. Please try again.");
    } finally {
      setFileLoader(false);
    }
  };

  return (
    <ModalProvider
      isOpen={isShowModal}
      appElement={document.getElementById("root")}
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
    >
      <div className="flex flex-col justify-center w-full max-w-2xl p-8 bg-white-A700 rounded-lg">
        <div className="flex justify-between items-center  mb-2">
          <Text size="lg" as="p" className="tracking-[0.18px]">
            Export Data Confirmation
          </Text>
        </div>

        <Text size="sm" as="p" className="tracking-[0.18px] mb-4">
          A total of {total} records are available for export. Please note that
          you can only export a maximum of {EXPORT_LIMIT} records at a time.
        </Text>

        <div className="w-full mb-3">
          <CustomInput
            label="Export Name"
            placeholder="Enter a unique name for export"
            value={filename}
            onChange={(e) => setFilename(e.target.value)}
          />
          <CustomInput
            label="Number of Records"
            placeholder="Enter a number"
            type="number" // This will ensure only numeric input
            min="1"
            max={total} // Set the max limit to the total records
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
          />
        </div>
        <div className="flex justify-between w-full gap-4 sm:flex-col sm:flex-col-reverse">
          <Button
            shape="round"
            className="tracking-[1.25px] uppercase font-medium min-w-[150px]"
            onClick={onClose}
          >
            Cancel
          </Button>
          <div className="flex">
            {fileLoader ? (
              <Spinner />
            ) : (
              <>
                <Button
                  shape="round"
                  color="teal_700"
                  className="tracking-[1.25px] uppercase font-medium hover:bg-gray-100 rounded-md"
                  onClick={() => handleSubmit("xlsx")}
                  leftIcon={
                    <Img
                      src="images/csv.png"
                      alt="CSV"
                      className="w-10 cursor-default"
                    />
                  }
                >
                  Excel File
                </Button>
                <Button
                  shape="round"
                  color="teal_700"
                  className="tracking-[1.25px] uppercase font-medium hover:bg-gray-100 rounded-md"
                  onClick={() => handleSubmit("csv")}
                  leftIcon={
                    <Img
                      src="images/csv.png"
                      alt="CSV"
                      className="w-10 cursor-default"
                    />
                  }
                >
                  Csv File
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </ModalProvider>
  );
}

const SliderComponent = ({
  fixMin,
  fixMax,
  min,
  max,
  label,
  filterKey,
  handleFilterChange,
}) => {
  const [minPrice, setMinPrice] = useState(min);
  const [maxPrice, setMaxPrice] = useState(max);
  const [minThumb, setMinThumb] = useState(0); // Set initial min thumb position
  const [maxThumb, setMaxThumb] = useState(100); // Set initial max thumb position
  const [isDraggingMin, setIsDraggingMin] = useState(false);
  const [isDraggingMax, setIsDraggingMax] = useState(false);
  const sliderRef = useRef(null);

  // Update min and max thumbs when min and max values change
  useEffect(() => {
    setMinPrice(min);
    setMaxPrice(max);
    setMinThumb(((min - fixMin) / (fixMax - fixMin)) * 100); // Thumb position is based on fixMin and fixMax
    setMaxThumb(((max - fixMin) / (fixMax - fixMin)) * 100);
  }, [min, max, fixMin, fixMax]);

  const minTrigger = (value) => {
    const updatedMinPrice = Math.max(fixMin, Math.min(value, maxPrice)); // Constrain between fixMin and maxPrice
    setMinPrice(updatedMinPrice);
    setMinThumb(((updatedMinPrice - fixMin) / (fixMax - fixMin)) * 100); // Update thumb position
    handleFilterChange(filterKey, { min: updatedMinPrice, max: maxPrice });
  };

  const maxTrigger = (value) => {
    const updatedMaxPrice = Math.min(fixMax, Math.max(value, minPrice)); // Constrain between minPrice and fixMax
    setMaxPrice(updatedMaxPrice);
    setMaxThumb(100 - ((updatedMaxPrice - fixMin) / (fixMax - fixMin)) * 100); // Update thumb position
    handleFilterChange(filterKey, { min: minPrice, max: updatedMaxPrice });
  };

  const handleMinThumbDrag = (e) => {
    if (!sliderRef.current) return;
    const sliderRect = sliderRef.current.getBoundingClientRect();
    const newMinThumbPosition =
      ((e.clientX - sliderRect.left) / sliderRect.width) * 100;
    const newMinPrice =
      fixMin + (newMinThumbPosition / 100) * (fixMax - fixMin);
    minTrigger(Math.floor(newMinPrice));
  };

  const handleMaxThumbDrag = (e) => {
    if (!sliderRef.current) return;
    const sliderRect = sliderRef.current.getBoundingClientRect();
    const newMaxThumbPosition =
      ((e.clientX - sliderRect.left) / sliderRect.width) * 100;
    const newMaxPrice =
      fixMin + (newMaxThumbPosition / 100) * (fixMax - fixMin);
    maxTrigger(Math.ceil(newMaxPrice));
  };

  const handleThumbMouseDown = (thumb) => {
    if (thumb === "min") {
      setIsDraggingMin(true);
    } else {
      setIsDraggingMax(true);
    }
  };

  const handleMouseUp = () => {
    setIsDraggingMin(false);
    setIsDraggingMax(false);
  };

  useEffect(() => {
    if (isDraggingMin) {
      const handleMouseMove = (e) => handleMinThumbDrag(e);
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };
    } else if (isDraggingMax) {
      const handleMouseMove = (e) => handleMaxThumbDrag(e);
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };
    }
  }, [isDraggingMin, isDraggingMax]);

  const validateInput = (value, isMinInput) => {
    if (isMinInput) {
      return Math.max(fixMin, Math.min(value, maxPrice));
    } else {
      return Math.min(fixMax, Math.max(value, minPrice));
    }
  };

  return (
    <div className="flex flex-col gap-2 bg-gray-50 rounded-md my-2 px-2 py-2">
      <div className="relative  w-full h-2 my-2" ref={sliderRef}>
        <div className="absolute  left-0 right-0 bottom-0 top-0 rounded-md bg-gray-200"></div>
        <div
          className="absolute  top-0 bottom-0 rounded-md bg-green-300"
          style={{ right: `${100 - maxThumb}%`, left: `${minThumb}%` }} // Adjust thumb positions
        ></div>
        <div
          className="absolute w-6 h-6 top-0 left-0 bg-green-300 rounded-full -mt-2 -ml-1 cursor-pointer"
          style={{ left: `${minThumb}%` }}
          onMouseDown={() => handleThumbMouseDown("min")}
        ></div>
        <div
          className="absolute  w-6 h-6 top-0 right-0 bg-green-300 rounded-full -mt-2 -mr-3 cursor-pointer"
          style={{ right: `${100 - maxThumb}%` }}
          onMouseDown={() => handleThumbMouseDown("max")}
        ></div>
      </div>
      <div className="flex items-center justify-between gap-3">
        <div className="bg-gray-200 p-2 rounded-lg">
          <input
            type="number"
            maxLength="5"
            value={minPrice}
            onChange={(e) =>
              minTrigger(validateInput(Number(e.target.value), true))
            }
            className="rounded w-15 text-center"
          />
        </div>
        <div className="bg-gray-200 p-2 rounded-lg">
          <input
            type="number"
            maxLength="5"
            value={maxPrice}
            onChange={(e) =>
              maxTrigger(validateInput(Number(e.target.value), false))
            }
            className="rounded w-15 text-center"
          />
        </div>
      </div>
    </div>
  );
};

const ToggleSection = ({ isOpen, handler, label, dropDown = true }) => {
  return (
    <div
      className="flex justify-between items-center cursor-pointer bg-gray-200 p-2 rounded-sm"
      onClick={handler}
    >
      <Text as="p" className="self-end tracking-[0.50px]">
        {label}
      </Text>
      {isOpen ? (
        <>
          {dropDown === true ? (
            <ChevronUpIcon className="h-6 w-6" />
          ) : (
            <MinusIcon className="h-6 w-6" />
          )}
        </>
      ) : (
        <>
          {dropDown === true ? (
            <ChevronDownIcon className="h-6 w-6" />
          ) : (
            <PlusIcon className="h-6 w-6" />
          )}
        </>
      )}
    </div>
  );
};

const LIBRARIES = ["places"];
export default function Home() {
  const { userObj } = useSelector((state) => state.user);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAP_KEY,
    libraries: LIBRARIES,
  });

  const INIT_VALUE = {
    location: {
      lat: null,
      lng: null,
      zipCode: "",
      address: "",
    },
    isOpen: {
      loca: false,
      ser_off: true,
      bus_info: false,
      device_type: false,
      review: false,
      rating: false,
      mapView: true,
      tab: SEARCH,
      locationTab: NEARBY_AREA,
      filtersShow: true,
      submit: false,
    },
    filters: {
      radius: RADIUS_OPTIONS[0],
      cinfo: false,
      rating: { min: 0, max: 5 },
      reviews: { min: 0, max: 100000 },
      states: [],
      category_titles: [],
      products: [],
      categories: [],
      brands: [],
      devices: false,
      injectables: false,
    },
  };
  const TABS = [SEARCH];
  // const TABS = [SEARCH, SAVED_SEARCH];

  const [saveSearch, setSaveSearch] = useState(null);
  const [exportQuery, setExportQuery] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [searches, setSearches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState({
    totalPages: 1,
    data: [],
    totalItems: null,
  });
  const [isOpen, setIsOpen] = useState(INIT_VALUE.isOpen);
  const [location, setLocation] = useState(INIT_VALUE.location);
  const [filters, setFilters] = useState(INIT_VALUE.filters);

  const getFilters = () => {
    const {
      states,
      radius,
      products,
      brands,
      categories,
      category_titles,
      cinfo,
      rating,
      reviews,
      devices,
      injectables,
    } = filters;
    const { review, rating: isRatingOpen, device_type: hasDeviceType } = isOpen;
    const zipCode = location.zipCode;
    const hasRadius = radius && radius !== null;
    const hasZipCode = Boolean(zipCode);
    const hasStates = states && states.length > 0;
    const hasProducts = products && products.length > 0;
    const hasCategories = categories && categories.length > 0;
    const hasBrands = brands && brands.length > 0;
    const hasCategoryTitles = category_titles && category_titles.length > 0;
    const hasCinfo = cinfo !== false; // Check if `cinfo` is selected
    let device_type = null;
    if (hasDeviceType) {
      if (devices && injectables) {
        device_type = "both"; // If both are selected, set device_type to "both"
      } else if (devices) {
        device_type = "devices"; // If only devices is selected
      } else if (injectables) {
        device_type = "injectables"; // If only injectables is selected
      }
    }
    let locationParams = {};
    let productData = {};
    let businessData = {};

    // If zipCode and radius are provided, use nearby location
    if (hasRadius && hasZipCode) {
      locationParams.nearby = { zipCode, radius: getRadius(radius.label) };
    }

    // If states are provided, add them to the location params
    if (hasStates) {
      locationParams.states = states;
    }

    // If products, categories, brands, or device_type are provided, add them to productData
    if (hasProducts || hasCategories || hasBrands || hasDeviceType) {
      productData = {
        ...(hasProducts && { products }),
        ...(hasCategories && { categories }),
        ...(hasBrands && { brands }),
        ...(hasDeviceType && { device_type: device_type }),
      };
    }

    // If category_titles, cinfo, rating, or reviews are provided, add them to businessData
    if (
      hasCategoryTitles ||
      hasCinfo ||
      (isRatingOpen && rating) ||
      (review && reviews)
    ) {
      businessData = {
        ...(hasCategoryTitles && { category_titles }),
        ...(hasCinfo && { cinfo }),
        ...(isRatingOpen && rating && { rating }),
        ...(review && reviews && { reviews }),
      };
    }

    if (
      !Object.keys(locationParams).length &&
      !productData?.products?.length &&
      !productData?.brands?.length &&
      !productData?.categories?.length &&
      !businessData?.category_titles?.length
    ) {
      return [false, "Please select a filter."];
    }

    return [
      true,
      {
        ...(Object.keys(locationParams).length > 0 && {
          location: locationParams,
        }),
        ...(Object.keys(productData).length > 0 && {
          product_data: productData,
        }),
        ...(Object.keys(businessData).length > 0 && { business: businessData }),
      },
    ];
  };

  const handleToggle = (filterName, val) => {
    setIsOpen((prev) => ({
      ...prev,
      [filterName]: val, // Toggle the specific filter
    }));
  };

  const handleFilterChange = (field, value) => {
    if (
      [
        "states",
        "category_titles",
        "products",
        "categories",
        "brands",
      ].includes(field) &&
      value.length > 5
    ) {
      toast.error("You can't select more than 5 items");
      return;
    }
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const handleResetButton = () => {
    setRecords({
      totalPages: 1,
      data: [],
      totalItems: null,
    });
    setLocation(INIT_VALUE.location);
    setIsOpen(INIT_VALUE.isOpen);
    setFilters(INIT_VALUE.filters);
  };

  function getRadius() {
    const val = filters.radius ? filters.radius.label : RADIUS_OPTIONS[0].label;
    let r = parseInt(val.replace(" Miles", "").trim());
    return milesToKilometers(r) * 1000;
  }

  const loadDataList = async (page) => {
    const [isValid, filtersData] = getFilters();
    if (!isValid) {
      toast.error(filtersData);
      return;
    }

    // setShow(true);
    setLoading(true);

    try {
      const resp = await getAllRecord(userObj.token, page, filtersData);
      setRecords(resp);

      if (resp.totalItems > 0) {
        handleToggle("mapView", false);
        handleToggle("filtersShow", false);
      }
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.error || "Unknown error");
    }

    setLoading(false);
  };

  const handleSubmitButton = async () => {
    if (currentPage === 1) await loadDataList(1);
    else setCurrentPage(1);
  };

  const handleExport = async () => {
    const [isValid, filtersData] = getFilters();
    if (!isValid) {
      toast.error(filtersData);
      return;
    }
    setExportQuery(filtersData);
  };

  // TODO:
  const handleMapSelect = async (l) => {
    console.log(l);
    // await getZipCodeFromLatLong(l);
  };

  const handleSaveSearch = async () => {
    // console.log(data);
    // setSaveSearch(true);
  };
  useEffect(() => {
    const [isValid, filtersData] = getFilters();
    handleToggle("submit", isValid);
  }, [location, filters]);

  useEffect(() => {
    if (currentPage != null) {
      loadDataList(currentPage);
    }
  }, [currentPage]);

  return (
    <>
      <Helmet>
        <title>Aesthetic Locator</title>
        <meta name="description" content="Aesthetic Locator" />
      </Helmet>
      <div className="flex flex-col w-full gap-4 p-4 bg-gray-50 h-full">
        <Header />
        {isLoaded ? (
          <div className="w-full mx-auto">
            <div className="flex justify-center lg:gap-4  sm:flex-col">
              {isOpen.filtersShow && (
                <div className="w-[30vw] max-w-[30vw] p-2 bg-white-A700 rounded-[16px] sm:w-[95vw] sm:max-w-lg">
                  <div className="flex mb-3">
                    {TABS.map((txt, i) =>
                      txt === isOpen.tab ? (
                        <div
                          key={i}
                          className="flex flex-col items-center gap-[5px]"
                        >
                          <div className="flex mt-2">
                            <Text
                              as="p"
                              className="self-start !text-teal-300 tracking-[0.50px]"
                            >
                              {txt}
                            </Text>
                          </div>
                          <Img
                            src="images/img_selection_indicator.svg"
                            alt="selection_three"
                            className="h-[3px] w-full"
                          />
                        </div>
                      ) : (
                        <div
                          key={i}
                          className="flex p-2.5 cursor-pointer"
                          onClick={() => {
                            handleToggle("tab", txt);
                            // setMarkers([]);
                          }}
                        >
                          <div className="flex">
                            <Text as="p" className="self-end tracking-[0.50px]">
                              {txt}
                            </Text>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div className="overflow-y-auto px-1 scrollbar-custom h-[70vh]">
                    {isOpen.tab === SEARCH && (
                      <div className="flex flex-col justify-between h-full">
                        <div className="flex flex-col">
                          <div
                            className={`rounded-sm border-b-2 border-b-gray-400  mb-3`}
                          >
                            <ToggleSection
                              isOpen={isOpen.loca}
                              handler={() => handleToggle("loca", !isOpen.loca)}
                              label="Location"
                            />
                            {isOpen.loca && (
                              <div className="my-2">
                                <div className="flex mb-3 items-center">
                                  {LOCATION_TABS.map((txt, i) => (
                                    <div
                                      key={i}
                                      onClick={() => {
                                        handleToggle("locationTab", txt);
                                        setLocation(INIT_VALUE.location);
                                        handleFilterChange("radius", "");
                                        handleFilterChange("states", []);
                                      }}
                                      className={[
                                        "p-2 rounded-sm items-center justify-center cursor-pointer w-1/2",
                                        txt == isOpen.locationTab
                                          ? "bg-teal-300"
                                          : "bg-gray-300",
                                      ].join(" ")}
                                    >
                                      <Text
                                        as="p"
                                        className={[
                                          "tracking-[0.50px]",
                                          txt == isOpen.locationTab
                                            ? "text-white-A700"
                                            : "text-black",
                                        ].join(" ")}
                                      >
                                        {txt}
                                      </Text>
                                    </div>
                                  ))}
                                </div>
                                <div>
                                  {isOpen.locationTab === NEARBY_AREA && (
                                    <div>
                                      <GoogleAutocomplete
                                        location={location}
                                        setLocation={setLocation}
                                      />
                                      <SelectBox
                                        options={RADIUS_OPTIONS}
                                        placeholder="Select Radius"
                                        className="mt-2"
                                        value={filters.radius} // Bind the selected value
                                        onChange={(e) =>
                                          handleFilterChange("radius", e)
                                        }
                                      />
                                    </div>
                                  )}
                                  {isOpen.locationTab == SELECTED_STATES && (
                                    <MultipleSelectInput
                                      options={stateNames}
                                      label="Select State"
                                      placeholder="Search required states in here"
                                      selected={filters.states}
                                      setSelected={(v) =>
                                        handleFilterChange("states", v)
                                      }
                                      required={false}
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className={`rounded-sm border-b-2 border-b-gray-400 mb-3`}
                          >
                            <ToggleSection
                              isOpen={isOpen.ser_off}
                              handler={() =>
                                handleToggle("ser_off", !isOpen.ser_off)
                              }
                              label="Service Offering"
                            />

                            {isOpen.ser_off && (
                              <>
                                {SERVICE_OFFERING.map((input) => (
                                  <MultipleSelectInput
                                    key={input.key}
                                    options={input.options}
                                    label={input.label}
                                    placeholder={input.placeholder}
                                    selected={filters[input.key]}
                                    setSelected={(selected) =>
                                      handleFilterChange(input.key, selected)
                                    }
                                    customInput={true}
                                    required={false}
                                  />
                                ))}
                                <div
                                  className={`rounded-sm border-b-2 border-b-gray-400 my-3`}
                                >
                                  <ToggleSection
                                    isOpen={isOpen.device_type}
                                    handler={() =>
                                      handleToggle(
                                        "device_type",
                                        !isOpen.device_type
                                      )
                                    }
                                    label="Device Type"
                                  />
                                  {isOpen.device_type && (
                                    <div className="py-1 bg-white">
                                      <div className="space-y-2.5 my-1 px-3">
                                        {[
                                          {
                                            label: "Devices",
                                            value: "devices",
                                          },
                                          {
                                            label: "Injectables",
                                            value: "injectables",
                                          },
                                        ].map((option) => (
                                          <div
                                            key={option.value}
                                            className="transition-all duration-200 bg-white border rounded-lg cursor-pointer"
                                          >
                                            <div className="px-4 py-3">
                                              <div className="flex items-center">
                                                <input
                                                  type="checkbox"
                                                  name="device_type"
                                                  className="w-5 h-5 text-teal-600 border-gray-300"
                                                  checked={
                                                    filters[option.value]
                                                  }
                                                  onChange={(e) =>
                                                    handleFilterChange(
                                                      option.value,
                                                      e.target.checked
                                                    )
                                                  }
                                                />
                                                <p className="text-sm font-medium text-gray-900 ml-2.5">
                                                  {option.label}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          <div
                            className={`rounded-sm border-b-2 border-b-gray-400 mb-3`}
                          >
                            <ToggleSection
                              isOpen={isOpen.bus_info}
                              handler={() =>
                                handleToggle("bus_info", !isOpen.bus_info)
                              }
                              label="Business Info"
                            />
                            {isOpen.bus_info && (
                              <div className="my-2">
                                <MultipleSelectInput
                                  options={OPTIONS["google"]["category_title"]}
                                  placeholder={`Search ${CATEGORY_TITLE}`}
                                  label={CATEGORY_TITLE}
                                  selected={filters.category_titles}
                                  setSelected={(categories) => {
                                    handleFilterChange(
                                      "category_titles",
                                      categories
                                    );
                                  }}
                                  customInput={true}
                                  required={false}
                                />
                                <div className="my-2 flex justify-between items-center bg-gray-100 p-2 rounded-md">
                                  <Text
                                    as="p"
                                    className="self-end tracking-[0.50px]"
                                  >
                                    Both email & phone present
                                  </Text>
                                  <Checkbox
                                    value={filters.cinfo}
                                    onChange={(e) =>
                                      handleFilterChange(
                                        "cinfo",
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                                <div
                                  className={`rounded-sm border-b-2 border-b-gray-400 mb-3`}
                                >
                                  <ToggleSection
                                    isOpen={isOpen.rating}
                                    handler={() =>
                                      handleToggle("rating", !isOpen.rating)
                                    }
                                    dropDown={false}
                                    label="Ratings"
                                  />
                                  {isOpen.rating && (
                                    <SliderComponent
                                      fixMin={0}
                                      fixMax={5}
                                      min={filters.rating.min}
                                      max={filters.rating.max}
                                      label="Rating"
                                      filterKey="rating"
                                      handleFilterChange={handleFilterChange}
                                    />
                                  )}
                                </div>

                                <div
                                  className={`rounded-sm border-b-2 border-b-gray-400 mb-3`}
                                >
                                  <ToggleSection
                                    isOpen={isOpen.review}
                                    handler={() =>
                                      handleToggle("review", !isOpen.review)
                                    }
                                    label="Reviews"
                                    dropDown={false}
                                  />
                                  {isOpen.review && (
                                    <SliderComponent
                                      fixMin={0}
                                      fixMax={100000}
                                      min={filters.reviews.min}
                                      max={filters.reviews.max}
                                      label="Reviews"
                                      filterKey="reviews"
                                      handleFilterChange={handleFilterChange}
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col mt-2">
                          <div className="flex my-1 gap-2">
                            <Button
                              variant="gradient"
                              color="teal_300_teal_600"
                              // shape="round"
                              className="w-full sm:px-5 tracking-[1.25px] uppercase font-medium bg-[#cacacc] rounded-md"
                              onClick={handleResetButton}
                            >
                              Reset
                            </Button>
                            <Button
                              variant="gradient"
                              disabled={!isOpen.submit} // This will disable the button if isOpen.submit is true
                              color="teal_300_teal_600"
                              className="w-full sm:px-5 tracking-[1.25px] uppercase font-medium rounded-md"
                              onClick={handleSubmitButton}
                            >
                              Submit
                            </Button>
                          </div>
                          {/* <Button
                            className="w-full sm:px-5 tracking-[1.25px] uppercase font-medium bg-[#cacacc] rounded-md"
                            onClick={handleSaveSearch}
                          >
                            Save Search
                          </Button> */}
                          <div class="flex flex-col items-center justify-center my-2 group sm:hidden">
                            <a
                              className="flex items-center justify-center text-teal-400 font-medium text-sm group-hover:text-teal-700"
                              href="https://tally.so/r/3jLEqx"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src="images/img_link.svg"
                                alt="Edit"
                                className="min-w-[22px]"
                              />
                              Are We Missing A Brand or Product?
                            </a>
                            <span class="text-center text-teal-400 font-medium text-sm group-hover:text-teal-700">
                              Suggest An Edit
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* {isOpen.tab === SAVED_SEARCH && (
                      <>
                        {searches &&
                          searches.map((item, i) => {
                            return (
                              <div
                                key={i}
                                className="border bg-[#e8e8e8] rounded-[16px] p-2 mt-2"
                              >
                                <Text>{item.name}</Text>
                              </div>
                            );
                          })}
                      </>
                    )} */}
                  </div>
                </div>
              )}
              <div
                className={`bg-white-A700 rounded-[16px] w-full gap-3 p-2 flex-col ${
                  isOpen.filtersShow ? "max-w-[70vw] sm:max-w-[100vw]" : "max-w-[95vw]"
                }`}
              >
                <Data
                  record={records}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                  mapView={isOpen.mapView}
                  setMapView={(v) => handleToggle("mapView", v)}
                  location={location}
                  handleMapSelect={handleMapSelect}
                  radius={getRadius()}
                  isOpen={isOpen}
                  handleExport={handleExport}
                  handleToggle={handleToggle}
                  loading={loading}
                />
              </div>
            </div>
            <CreateSearch
              isOpen={saveSearch !== null}
              payload={saveSearch}
              handleClose={() => {
                setSaveSearch(null);
              }}
            />
          </div>
        ) : (
          <Spinner />
        )}
      </div>
      {exportQuery && (
        <ExportModal
          isShowModal={exportQuery !== null}
          onClose={() => setExportQuery(null)}
          filters={exportQuery}
          total={records?.totalItems || 0}
          token={userObj.token}
        />
      )}
    </>
  );
}
