import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Text, Heading, Img } from "../../components";
import Header from "components/Header";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getBillingPortal } from "api/stripe";
import { Spinner } from "components/Spinner";
import UpdateInformationModal from "modals/UpdateInformation";

export default function Settings() {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user.userObj);
  const [editInfo, setEditInfo] = useState(null);
  const handleManagePayment = async () => {
    setLoading(true);
    await getBillingPortal(user.token)
      .then((data) => {
        window.open(data.url, "_blank");
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => setLoading(false));
  };
  const handleDeleteAccount = () => {
    toast.error("Can't delete account");
  };

  return (
    <>
      <Helmet>
        <title>Google Map</title>
        <meta name="description" content="Aesthetic Locator" />
      </Helmet>
      <div className="flex flex-col w-full gap-4 p-8 sm:p-5 bg-gray-50">
        {/* <div className="flex w-full justify-center bg-gray-50 p-8 sm:p-5"> */}
        <Header />
        {/* <div className="w-full mx-auto max-w-[1856px]"> */}
        <div className="mx-auto w-full max-w-[1856px] rounded-[16px] bg-white-A700 p-8 md:p-5">
          <div className="mb-[5px] flex flex-col gap-8">
            {/* <div className="flex items-center justify-between gap-5 pr-[793px] md:pr-5 sm:flex-col">
                            <Button
                                variant="outline"
                                shape="round"
                                leftIcon={<Img src="images/img_thumbsup.svg" alt="thumbs_up" className="h-[24px] w-[24px]" />}
                                className="min-w-[167px] gap-1.5 font-medium uppercase tracking-[1.25px]"
                            >
                                Back to home
                            </Button>
                            <Img
                                src="logo.png"
                                alt="imageseven_one"
                                className="h-[32px] w-[20%] object-cover sm:w-full"
                            />
                        </div> */}
            <div className="flex flex-col gap-[15px] rounded-[17px] border border-solid border-gray-300_01 bg-white-A700 p-[31px] sm:p-5">
              <div className="flex items-center justify-between gap-5">
                <Text size="lg" as="p" className="tracking-[0.18px]">
                  PERSONAL INFORMATION
                </Text>
                <Button
                  onClick={() => {
                    setEditInfo(user);
                  }}
                  shape="round"
                  color="teal_300_teal_600"
                  className="min-w-[201px] font-medium uppercase tracking-[1.25px]"
                >
                  Edit my information
                </Button>
              </div>
            </div>
            <div className="h-px bg-gray-300_01" />
            {/* <div className="flex flex-wrap items-center justify-between gap-5">
                            <Heading size="s" as="h1">
                                Name
                            </Heading>
                            <Text as="p" className="self-start text-right !text-gray-600">
                                {user.name}
                            </Text>
                        </div> */}
            {/* <div className="flex flex-wrap items-center justify-between gap-5">
                            <Heading size="s" as="h2">
                                Last name
                            </Heading>
                            <Text as="p" className="self-start text-right !text-gray-600">
                                not defined
                            </Text>
                        </div> */}
            {/* <div className="flex flex-wrap items-center justify-between gap-5">
                            <Heading size="s" as="h3">
                                Date of birth
                            </Heading>
                            <Text as="p" className="text-right !text-gray-600">
                                not defined
                            </Text>
                        </div> */}
          </div>
          <div className="flex gap-8 md:flex-col">
            <div className="flex w-full flex-col items-center gap-[15px] rounded-[17px] border border-solid border-gray-300_01 bg-white-A700 p-[31px] sm:p-5">
              <div className="flex self-start py-1.5">
                <Text size="lg" as="p" className="self-start tracking-[0.18px]">
                  Email
                </Text>
              </div>
              <div className="h-px self-stretch bg-gray-300_01" />
              <div className="flex flex-wrap items-center justify-between gap-5 self-stretch">
                <Heading size="s" as="h4">
                  Email
                </Heading>
                <Text as="p" className="self-end text-right !text-gray-600">
                  {user.email}
                </Text>
              </div>
            </div>
            <div className="flex w-full flex-col gap-[15px] rounded-[17px] border border-solid border-gray-300_01 bg-white-A700 p-[31px] sm:p-5">
              <div className="flex items-start justify-between gap-5">
                <Text size="lg" as="p" className="mt-1.5 tracking-[0.18px]">
                  Name
                </Text>
                {/* <Button
                                    shape="round"
                                    color="teal_300_teal_600"
                                    className="min-w-[179px] font-medium uppercase tracking-[1.25px]"
                                >
                                    Change Password
                                </Button> */}
              </div>
              <div className="h-px bg-gray-300_01" />
              <div className="flex flex-wrap items-center justify-between gap-5">
                <Heading size="s" as="h5">
                  Name
                </Heading>
                <Text as="p" className="self-end text-right !text-gray-600">
                  {user.name}
                </Text>
              </div>
            </div>
          </div>
          <div className="mt-8 flex flex-col gap-[15px] rounded-[17px] border border-solid border-gray-300_01 bg-white-A700 p-8 sm:p-5">
            <div className="flex self-start py-2">
              <Text size="lg" as="p" className="self-end tracking-[0.18px]">
                Additional Options
              </Text>
            </div>
            <div className="h-px bg-gray-300_01" />
            <div className="flex gap-4 md:flex-col">
              {/* <Button
                                shape="round"
                                color="teal_300_teal_600"
                                className="w-full font-medium uppercase tracking-[1.25px] sm:px-5"
                            >  Invoices
                            </Button> */}
              <Button
                variant="gradient"
                shape="round"
                color="teal_300_teal_600"
                className="font-medium uppercase tracking-[1.25px] sm:px-5"
                onClick={handleManagePayment}
                disabled={loading}
              >
                {loading ? <Spinner /> : "Manage Payment"}
              </Button>

              {/* <Button
                                variant="fill"
                                shape="round"
                                className="w-full font-medium uppercase tracking-[1.25px] sm:px-5"
                                style={{ backgroundColor: "#F44336", color: "white" }}
                                onClick={handleDeleteAccount}
                            >
                                DELETE ACCOUNT
                            </Button> */}
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
      {editInfo && (
        <UpdateInformationModal
          isShowModal={editInfo !== null}
          modalData={editInfo}
          onModalClose={() => setEditInfo(null)}
        />
      )}
    </>
  );
}
