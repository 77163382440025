import React, { useEffect, useState } from "react";
import { useRoutes, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import SignIn from "pages/SignIn";
import SignUp from "pages/SignUp";
import ForgotPassword from "pages/ForgotPassword";
import Verify from "pages/Verify";
import Success from "pages/Success";
import Settings from "pages/Settings";
import Admin from "pages/Admin";
import ResetPassword from "pages/ResetPassword";
import { getUser } from "api/auth";
import { Spinner } from "components/Spinner";
import StripePricingTable from "components/Stripe";
import Results from "pages/Results";
// import Pricing from "pages/Pricing";

const PrivateRoute = ({ element, requiresAdmin = false }) => {
  const { pathname } = useLocation();
  const { userObj } = useSelector((state) => state.user);
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [isCheckingToken, setIsCheckingToken] = useState(true);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const checkTokenValidity = (token) => {
    if (!token) return false;

    const tokenPayload = JSON.parse(atob(token.split(".")[1]));
    const expiry = tokenPayload.exp * 1000; // Convert expiry to milliseconds
    return expiry > Date.now();
  };

  const verifyTokenWithBackend = async (token) => {
    try {
      const data = await getUser(token);
      if (data.planId !== "not paid" || data.credits > 0) {
        setIsPaid(true);
      } else {
        setIsPaid(false);
      }

      if (data.admin) {
        setIsAdmin(true); // Set admin state if the user is an admin
      } else {
        setIsAdmin(false); // Ensure admin state is false for non-admins
      }
      window.Atlas.call("identify", {
        userId: data._id, // Use actual userId
        name: data.name, // Use actual user name
        email: data.email, // Use actual user email
        customFields: {
          product: "Aesthetic Locator Sales",
        },
      });

      return true;
    } catch (error) {
      console.error("Error verifying token with backend:", error);
      return false;
    }
  };

  useEffect(() => {
    const checkToken = async () => {
      if (userObj && userObj.token) {
        if (userObj.type && userObj.type === "reset-password") {
          setIsTokenValid(false);
          setIsCheckingToken(false);
          setIsResetPassword(true);
          return;
        } else {
          const localValid = checkTokenValidity(userObj.token);
          if (!localValid) {
            setIsTokenValid(false);
            setIsCheckingToken(false);
            return;
          } else {
            const backendValid = await verifyTokenWithBackend(userObj.token);
            setIsTokenValid(backendValid);
          }
        }
      } else {
        setIsTokenValid(false);
      }
      setIsCheckingToken(false);
    };

    checkToken();
  }, [userObj]);

  if (isCheckingToken) {
    return (
      <div className="flex justify-center items-center w-screen h-screen">
        <Spinner />
      </div>
    );
  }

  // If the token is invalid or no user is logged in, redirect to sign-in
  if (!userObj || !isTokenValid) {
    if (pathname === "/reset-password" && isResetPassword) {
      return element;
    }
    return <Navigate to="/sign-in" />;
  }

  if (isPaid && pathname === "/pricing") {
    return <Navigate to="/" />;
  }
  if (!isPaid && pathname !== "/pricing" && !isAdmin) {
    return <Navigate to="/pricing" />;
  }
  // If the route requires admin privileges and the user is not an admin, show Not Found
  if (requiresAdmin && !isAdmin) {
    return <Navigate to="/not-found" />;
  }

  return element;
};

const ProjectRoutes = () => {
  const { userObj } = useSelector((state) => state.user);
  const isUserSignedIn = userObj !== null;

  const PAGES = [{ title: "Home", urls: ["/"], element: <Home /> }];

  const routes = [
    {
      path: "sign-in",
      element: <SignIn />,
    },
    {
      path: "/",
      element: <PrivateRoute element={<Home />} />,
    },
    {
      path: "/settings",
      element: <PrivateRoute element={<Settings />} />,
    },
    {
      path: "/results",
      element: <PrivateRoute element={<Results />} />,
    },
    {
      path: "/admin",
      element: <PrivateRoute element={<Admin />} requiresAdmin={true} />, // Add requiresAdmin=true
    },
    {
      path: "/pricing",
      element: <PrivateRoute element={<StripePricingTable />} />,
    },
    {
      path: "/success",
      element: <Success />,
    },
    {
      path: "/sign-up",
      element: <SignUp />,
    },
    {
      path: "/verify",
      element: <Verify />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },

    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  // Add the privileged routes if the user is signed in and has a privileged email
  if (isUserSignedIn) {
    PAGES.forEach((page) => {
      routes.push({
        path: page.urls[0],
        element: <PrivateRoute element={page.element} />,
      });
    });
  }

  const element = useRoutes(routes);

  return element;
};

export default ProjectRoutes;
