import React from "react";

const Spinner = ({ size = 10 }) => {
  return (
    <div
      className={`border-gray-300 animate-spin rounded-full border-4 border-t-teal-600 h-${size} w-${size}`}
    />
  );
};

export { Spinner };
