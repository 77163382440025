// FIXME: restrict to us only
import React, { useState, useEffect } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { GOOGLE_MAP_KEY } from "config/config";
import axios from "axios";

const NOT_VALID = "Not Valid";

const getPostalCode = (data) => {
  for (let i = 0; i < data.length; i++) {
    const addressComponents = data[i].address_components;
    for (let j = 0; j < addressComponents.length; j++) {
      const component = addressComponents[j];
      if (component.types.includes("postal_code")) {
        return component;
      }
    }
  }
  return null; // Return null if postal code is not found
};

const GoogleAutocomplete = ({ location, setLocation }) => {
  const [autocomplete, setAutocomplete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  // Function to get lat and long from the address
  const getLatLongFromAddress = async (address) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${GOOGLE_MAP_KEY}`
      );
      if (!response.ok) {
        setError(true);
        return NOT_VALID;
      }
      const data = await response.json();
      if (data.status === "OK") {
        return data.results[0].geometry.location;
      } else {
        console.error(data.status);
      }
    } catch (error) {
      console.error(error.message);
    }
    return NOT_VALID;
  };

  // Function to get the zip code from latitude and longitude
  const getZipCodeFromLatLong = async (loc) => {
    try {
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${loc.lat},${loc.lng}&key=${GOOGLE_MAP_KEY}`;
      const response = await axios.get(url);
      const component = getPostalCode(response.data.results);
      const zipCode = component.long_name;
      return zipCode;
    } catch (err) {
      setError(true);
      return NOT_VALID;
    }
  };

  const checkAddress = async () => {
    if (!location.address) return;
    const loc = await getLatLongFromAddress(location.address);
    if (loc !== NOT_VALID) {
      const zipCode = await getZipCodeFromLatLong(loc);
      if (zipCode !== NOT_VALID) {
        setLocation((prev) => ({
          ...prev,
          lat: loc.lat,
          lng: loc.lng,
          zipCode,
        }));
      } else {
        setError(true);
      }
    } else {
      setError(true);
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await checkAddress(); // Check the address when "Enter" is pressed
    }
  };

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const onPlaceChanged = async () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      // if (place && place.formatted_address) {
      if (place && place.address_components?.length>0&&place.address_components[0].long_name) {
        setLocation((prev) => ({
          ...prev,
          address: place.formatted_address,
        }));
        await checkAddress(); // Check the address when a place is selected
      }
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setLocation((prev) => ({
      ...prev,
      address: e.target.value,
    }));
    setError(false);
    setLoading(true);
  };

  const handleClear = () => {
    setLocation({
      lat: null,
      lng: null,
      zipCode: "",
      address: "",
    });
    setLoading(false);
    setError(false);
  };

  useEffect(() => {
    if (location.address === "") {
      setLoading(false);
    }
  }, [location.address]);

  return (
    <Autocomplete
      onLoad={onLoad}
      onPlaceChanged={onPlaceChanged}
      componentRestrictions={{ country: "us" }}
    >
      <div
        className={`flex items-center p-3 border rounded-md bg-gray-50 ${
          error ? "border-red-500" : "border-gray-300"
        }`}
      >
        <input
          type="text"
          placeholder={error ? "Invalid location" : "Search location here"}
          value={location.address}
          onChange={handleChange}
          className="text-gray-400 flex-grow focus:outline-none"
          required={false}
          onKeyDown={handleKeyDown}
        />

        {/* Spinner or Clear Icon */}
        {loading ? (
          <svg
            role="status"
            className="inline h-6 w-6 animate-spin mr-2 text-gray-200 dark:text-gray-600 fill-teal-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        ) : location.address ? (
          <div className="cursor-pointer" onClick={handleClear}>
            <svg
              className="h-5 w-5 text-gray-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        ) : null}
      </div>
    </Autocomplete>
  );
};

export default GoogleAutocomplete;
