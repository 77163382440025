// Filter headings
export const NEARBY_AREA = "Nearby Areas";
export const SELECTED_STATES = "States";
export const WHOLE_USA = "Whole USA";
//
export const SEARCH = "Search";
export const SAVED_SEARCH = "Saved Searches";
//
// Data related
export const EXPORT_LIMIT = 20000;
export const RADIUS_OPTIONS = [
  { label: "5 Miles", value: 5 },
  { label: "10 Miles", value: 10 },
  { label: "25 Miles", value: 25 },
  { label: "50 Miles", value: 50 },
  { label: "100 Miles", value: 100 },
  { label: "200 Miles", value: 200 },
  { label: "500 Miles", value: 500 },
];

export const RATING_OPTIONS = [
  { label: "Below 1 Star", value: "0-1" },
  { label: "Between 2 to 3 Stars", value: "2-3" },
  { label: "Between 3 to 4 Stars", value: "3-4" },
  { label: "Between 4 to 5 Stars", value: "4-5" },
  { label: "5 Stars Only", value: "5-0" },
];
export const CATEGORY_TITLE = "Practice Type";
