import React, { useState } from "react";
import { Button, Text } from "../../components";
import { default as ModalProvider } from "react-modal";
import { Spinner } from "components/Spinner";
import { toast } from "react-toastify";
import MultipleSelectInput from "components/MultiSelectInput";
import CustomInput from "components/CustomInput";
import { bulkUpdateProducts } from "api/admin/categories";
export default function BulkUpdateModal({
  isShowModal,
  handleRefresh,
  products,
  onModalClose,
  token,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    select_field: [],
    select_value: [],
    new_value: "",
  });
  const handleBulkUpdate = async () => {
    if (
      !data.select_field.length ||
      !data.select_value.length ||
      !data.new_value
    ) {
      toast.error("All fields are required.");
      return;
    }
    try {
      const resp = await bulkUpdateProducts({
        select_field: data.select_field[0],
        select_value: data.select_value[0],
        new_value: data.new_value,
      },token);
      toast.success(resp.message || "Categories Successfully Updated");
      setData({
        select_field: [],
        select_value: [],
        new_value: "",
      });
      await handleRefresh()
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error); // Show server error message
      } else {
        toast.error("Unknown error occurred."); // Show generic error message
      }
    }
  };
  return (
    <ModalProvider
      {...props}
      appElement={document.getElementById("root")}
      isOpen={isShowModal}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center justify-center w-full max-w-lg p-8 bg-white-A700 rounded-lg">
        <Text size="lg" as="p" className="tracking-[0.18px] mb-4">
          Bulk Update
        </Text>

        <div className="w-full mb-3">
          <div className="flex items-center gap-4 mb-3 w-full">
            <div className="w-full">
              <MultipleSelectInput
                options={[
                  "Brand",
                  "Product Name",
                  "Treatment Category",
                  "Treatment Keywords",
                ]}
                label="Select Field"
                placeholder="Select required field"
                selected={data.select_field}
                setSelected={(select_field) =>
                  setData({ ...data, select_field })
                }
                multiselect={false}
              />
            </div>
            {data.select_field.length > 0 && (
              <div className="w-full">
                <MultipleSelectInput
                  options={products[data.select_field[0]]}
                  label="Value"
                  placeholder="Select value"
                  selected={data.select_value}
                  setSelected={(select_value) =>
                    setData({ ...data, select_value })
                  }
                  multiselect={false}
                />
              </div>
            )}
          </div>
          <CustomInput
            label="New Value"
            placeholder="Enter new value here"
            onChange={(e) => setData({ ...data, new_value: e.target.value })}
          />
        </div>
        <div className="flex justify-between w-full gap-4">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Button
                shape="round"
                className="tracking-[1.25px] uppercase font-medium min-w-[150px]"
                onClick={onModalClose}
              >
                Cancel
              </Button>
              <Button
                variant="gradient"
                shape="round"
                color="teal_300_teal_600"
                className="tracking-[1.25px] uppercase font-medium min-w-[150px]"
                style={{ backgroundColor: "#F44336", color: "white" }}
                onClick={handleBulkUpdate}
              >
                Update
              </Button>
            </>
          )}
        </div>
      </div>
    </ModalProvider>
  );
}
