import React, { useState } from "react";
import { Button, Text } from "../../components";
import { default as ModalProvider } from "react-modal";
import { Spinner } from "components/Spinner";
import { toast } from "react-toastify";
import CustomInput from "components/CustomInput";
import { createUser} from "api/admin/users";

export default function CreateUserModal({
  isShowModal,
  onModalClose,
  token,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [credits, setCredits] = useState(0); // Default value to 0
  const [email, setEmail] = useState(""); // State for email

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    // Validate email and credits
    if (!validateEmail(email)) {
      toast.error("Please enter a valid email.");
      return;
    }

    const intCredits = parseInt(credits, 10);
    if (isNaN(intCredits) || intCredits < 0) {
      toast.error("Credits must be a number and cannot be negative.");
      return;
    }

    setLoading(true);
    try {
      const data = await createUser(
        {
          email, // Pass the email
          credits: intCredits, // Pass the credits, can be zero
        },
        token
      );
      toast.success(data.message || "User updated successfully.");
      setEmail('')
      onModalClose(true); // Close modal after successful submission
    } catch (error) {
      toast.error(error.response?.data?.message || "Error updating user.");
      console.error("Error updating user:", error);
    } finally {
      setLoading(false);
    }
  };

  // Restrict input to numbers only for credits
  const handleCreditsChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setCredits(value);
    }
  };

  return (
    <ModalProvider
      {...props}
      appElement={document.getElementById("root")}
      isOpen={isShowModal}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center justify-center w-full max-w-lg p-8 bg-white-A700 rounded-lg">
        <Text size="lg" as="p" className="tracking-[0.18px] mb-4">
          Create User
        </Text>

        <div className="w-full mb-3 gap-2 flex flex-col">
          <CustomInput
            label="Email"
            placeholder="Enter user's email"
            required={true}
            value={email}
            onChange={(e) => setEmail(e.target.value)} // Update email value
          />
          <CustomInput
            label="Credits"
            placeholder="Enter credits for user (can be 0)"
            required={false}
            onChange={handleCreditsChange} // Restrict input to numbers
            value={credits}
          />
        </div>
        <div className="flex justify-between w-full gap-4">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Button
                shape="round"
                className="tracking-[1.25px] uppercase font-medium min-w-[150px]"
                onClick={()=>onModalClose(false)}
              >
                Cancel
              </Button>
              <Button
                variant="gradient"
                shape="round"
                color="teal_300_teal_600"
                className="tracking-[1.25px] uppercase font-medium min-w-[150px]"
                style={{ backgroundColor: "#F44336", color: "white" }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </>
          )}
        </div>
      </div>
    </ModalProvider>
  );
}
