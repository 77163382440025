import axios from "axios";
import { BACKEND_URL } from "../../config/config";

const VERSION = 'v1';

// Get products with token
const getUsers = async (token) => {
  try {
    const response = await axios.get(`${BACKEND_URL}/admin/api/${VERSION}/users?token=${token}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

const updateCredits = async (data,token) => {
  try {
    const response = await axios.put(`${BACKEND_URL}/admin/api/v1/update-credits?token=${token}`, data);

    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};


const createUser = async (data,token) => {
  try {
    const response = await axios.post(`${BACKEND_URL}/admin/api/v1/user?token=${token}`, data);

    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};


export { getUsers,updateCredits,createUser };
