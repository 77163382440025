import React from "react";
import { Button, Text } from "../../components";
import { default as ModalProvider } from "react-modal";
import { CloseSVG } from "assets/images";
import { CATEGORY_TITLE } from "config/constants";

const ShowDetails = ({ isShowModal, handleClose, item, filters = {} }) => {
  // Assuming filters are already in lowercase, no need to convert them here

  // Check if the item exists in the filter list
  const isMatchingItem = (item, filterList, isDict = true) => {
    let itemName = item;
    if (isDict) {
      itemName=itemName?.name?.toLowerCase();
    }
    itemName=itemName?.toLowerCase()
    return itemName ? filterList.includes(itemName) : false;
  };

  // Function to render lists, highlighting matching items
  const renderList = (
    title,
    list,
    isDict = true,
    border_bottom = true,
    bg = "#ecdbdbb8",
    filterList = []
  ) => {
    if (list && list.length > 0) {
      return (
        <div
          className={`my-4 flex ${
            border_bottom ? "border-b-gray-400 border-b" : ""
          }`}
        >
          <Text as="p" size="md" className="font-bold min-w-[140px]">
            {title}
          </Text>
          <div className="flex flex-wrap">
            {list.map((item, i) => (
              <div
                key={i}
                className={`p-1 m-1 rounded  rounded-r-lg ${
                  isMatchingItem(item, filterList, isDict)
                    ? "bg-[#98dcc6]"
                    : "bg-gray-200"
                }`}
                // style={{
                //   backgroundColor: isMatchingItem(item, filterList)
                //     ? "#ffadad"
                //     : bg,
                // }}
              >
                {isDict ? item.name : item}
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <ModalProvider
      isOpen={isShowModal}
      onRequestClose={handleClose}
      appElement={document.getElementById("root")}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex flex-col w-full max-w-[80%] p-8 bg-white-A700 rounded-lg max-h-[70vh] overflow-auto">
        <div className="flex justify-between items-center">
          <Text size="lg" as="p" className="tracking-[0.18px] mb-4">
            {item?.title}
          </Text>
          <CloseSVG
            fillColor="#e45353"
            onClick={handleClose}
            className="cursor-pointer"
          />
        </div>
        <div className="w-full mb-4">
          <div className="mb-2">
            <Text as="p" size="md">
              <strong>Address:</strong> {item.address}
            </Text>
          </div>
          {renderList(
            CATEGORY_TITLE,
            item.category_titles,
            false,
            true,
            "#b0ffecb8",
            filters.category_titles
          )}
          {renderList(
            "Products",
            item.product_data?.products,
            true,
            true,
            "#ecdbdbb8",
            filters.products
          )}
          {renderList(
            "Brands",
            item.product_data?.brands,
            true,
            true,
            "#ecdbdbb8",
            filters.brands
          )}
          {renderList(
            "Product Category",
            item.product_data?.categories,
            true,
            true,
            "#ecdbdbb8",
            filters.categories
          )}
          {renderList(
            "Product Subcategories",
            item.product_data?.sub_categories,
            true,
            true,
            "#ecdbdbb8",
            filters.sub_categories
          )}
          {renderList(
            "Business Tags",
            item.tags_titles,
            false,
            true,
            "#b0b9ffb8"
          )}
        </div>
        <div className="flex justify-between w-full gap-4">
          <Button
            variant="gradient"
            color="teal_300_teal_600"
            shape="round"
            className="tracking-[1.25px] uppercase font-medium min-w-[150px]"
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </div>
    </ModalProvider>
  );
};

export default ShowDetails;
