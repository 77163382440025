import React, { useState } from "react";
import { Img, Text, Input } from "./..";
import CustomInput from "components/CustomInput";
const PasswordInput = ({ name, onChange, placeholder = "Enter your password in here", label = "Password", showForgot = false ,...props}) => {
    const [showPassword, setShowPassword] = useState(false);
    const togglePassword = () => setShowPassword(!showPassword);
    return (
        <div className="flex flex-col self-stretch gap-2">
            <CustomInput
                onChange={onChange}
                name={name}
                placeholder={placeholder}
                label={label}
                type={showPassword ? "text" : "password"}
                suffix={<Img src={showPassword ? "images/img_eye_slash.svg" : "images/img_eye.svg"} alt="eye"
                    onClick={togglePassword}
                />}
                {...props}
            />
            {showForgot &&
                <a href="forgot-password">
                    <Text size="xs" as="p" className="!text-teal-600 tracking-[1.25px] uppercase underline">
                        Forgot Password?
                    </Text>
                </a>
            }
        </div>
    );
};
export { PasswordInput };


