import React, { useState } from "react";
import { Button, Text } from "../../components";
import { default as ModalProvider } from "react-modal";
import { Spinner } from "components/Spinner";
import { toast } from "react-toastify";
import CustomInput from "components/CustomInput";
import { updateCredits } from "api/admin/users";

export default function AddCredits({
  isShowModal,
  modalData,
  onModalClose,
  handleUpdate,
  token,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [credits, setCredits] = useState(modalData?.credits || 0);

  const handleUpdateUser = async () => {
    // Convert both credits to integer
    const intCredits = parseInt(credits, 10);
    const intModalCredits = parseInt(modalData.credits, 10);

    if (intCredits === intModalCredits) {
      toast.info("It's the same credits, please change the value.");
      return;
    }

    setLoading(true);
    try {
      const data = await updateCredits(
        {
          id: modalData._id, // Passing the user ID
          credits: intCredits, // Updated credits value
        },
        token
      );
      toast.success(data.message || "Credits updated successfully.");
      handleUpdate(); // Close modal and refresh if needed
    } catch (error) {
      toast.error(error.response?.data?.message || "Error updating credits.");
      console.error("Error updating credits:", error);
    } finally {
      setLoading(false);
    }
  };

  // Restrict input to numbers only
  const handleCreditsChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) { // Allow only numbers
      setCredits(value);
    }
  };

  return (
    <ModalProvider
      {...props}
      appElement={document.getElementById("root")}
      isOpen={isShowModal}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center justify-center w-full max-w-lg p-8 bg-white-A700 rounded-lg">
        <Text size="lg" as="p" className="tracking-[0.18px] mb-4">
          Update Credits
        </Text>

        <div className="w-full mb-3 gap-2 flex flex-col">
          <div className="flex bg-gray-100 p-2 rounded-md justify-between items-center">
            <Text>Name</Text>
            <Text>{modalData.name}</Text>
          </div>
          <div className="flex bg-gray-100 p-2 rounded-md justify-between items-center">
            <Text>Email</Text>
            <Text>{modalData.email}</Text>
          </div>
          <div className="flex bg-gray-100 p-2 rounded-md justify-between items-center">
            <Text>Stripe Id</Text>
            <Text>{modalData.stripeCustomerId}</Text>
          </div>
          <div className="flex bg-gray-100 p-2 rounded-md justify-between items-center">
            <Text>Plan Id</Text>
            <Text>{modalData.planId}</Text>
          </div>
          <CustomInput
            label="Credits"
            placeholder="Enter credits for user"
            required={false}
            onChange={handleCreditsChange} // Restrict input to numbers
            value={credits}
          />
        </div>
        <div className="flex justify-between w-full gap-4">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Button
                shape="round"
                className="tracking-[1.25px] uppercase font-medium min-w-[150px]"
                onClick={onModalClose}
              >
                Cancel
              </Button>
              <Button
                variant="gradient"
                shape="round"
                color="teal_300_teal_600"
                className="tracking-[1.25px] uppercase font-medium min-w-[150px]"
                style={{ backgroundColor: "#F44336", color: "white" }}
                onClick={handleUpdateUser}
              >
                Update
              </Button>
            </>
          )}
        </div>
      </div>
    </ModalProvider>
  );
}
