import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";
const shapes = {
    square: "rounded-[0px]",
    round: "rounded",
};
const variants = {
    outline: {
        gray_50: "border-gray-50 border border-solid text-teal-300",
    },
};
const sizes = {
    xs: "h-[24px] pr-[34px] text-lg",
    sm: "h-[36px] pl-3.5 pr-[35px] text-sm",
};
const SelectBox = React.forwardRef(
    (
        {
            children,
            className = "",
            options = [],
            isSearchable = false,
            isMulti = false,
            indicator,
            shape,
            variant = "outline",
            size = "xs",
            color = "gray_50",
            ...restProps
        },
        ref,
    ) => {
        // FIXME
        const cn=`self-stretch tracking-[0.50px] sm:pr-5 flex items-center justify-center text-base rounded-lg border-gray-900_33 border border-solid text-gray-900_99 h-[48px] px-3.5 ${className}`
        // const cn=`${className} flex ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""} ${(variant && variants[variant]?.[color]) || ""}`
        // console.log(cn)
        return (<>
            <Select
                ref={ref}
                options={options}
                className={cn}
                isSearchable={isSearchable}
                isMulti={isMulti}
                components={{
                    IndicatorSeparator: () => null,
                    ...(indicator && { DropdownIndicator: () => indicator }),
                }}
                styles={{
                    container: (provided) => ({
                        ...provided,
                        zIndex: 0,
                    }),
                    control: (provided) => ({
                        ...provided,
                        backgroundColor: "transparent",
                        border: "0 !important",
                        boxShadow: "0 !important",
                        minHeight: "auto",
                        width: "100%",
                        "&:hover": {
                            border: "0 !important",
                        },
                    }),
                    input: (provided) => ({
                        ...provided,
                        color: "inherit",
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? "#f9fafb" : "transparent",
                        color: state.isSelected ? "#707275" : "inherit",
                        "&:hover": {
                            backgroundColor: "#f9fafb",
                            color: "#707275",
                        },
                    }),
                    valueContainer: (provided) => ({
                        ...provided,
                        padding: 0,
                    }),
                    placeholder: (provided) => ({
                        ...provided,
                        margin: 0,
                    }),
                    menuPortal: (base) => ({ ...base, zIndex: 999999 }),
                }}
                menuPortalTarget={document.body}
                closeMenuOnScroll={(event) => {
                    return event.target.id === "scrollContainer";
                }}
                {...restProps}
            />
            {children}
        </>
        );
    },
);
SelectBox.propTypes = {
    className: PropTypes.string,
    options: PropTypes.array,
    isSearchable: PropTypes.bool,
    isMulti: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,
    indicator: PropTypes.node,
    shape: PropTypes.oneOf(["square", "round"]),
    size: PropTypes.oneOf(["xs", "sm"]),
    variant: PropTypes.oneOf(["outline"]),
    color: PropTypes.oneOf(["gray_50"]),
};
export { SelectBox };  