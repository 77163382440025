import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Button,
  Img,
  Text,
  Input,
  Warning,
  PasswordInput,
} from "../../components";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "store/slices/UserSlice";
import CustomInput from "components/CustomInput";
import { isValidEmail } from "../../utils/validations";
import { Spinner } from "components/Spinner";
import { BACKEND_URL } from "config/config";
export default function SignIn() {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [cBtn, setCBtn] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [inpval, setInpval] = useState({
    email: "",
    password: "",
  });
  const setVal = (e) => {
    const { name, value } = e.target;
    setInpval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    setError("");
    const con = isValidEmail(inpval.email) && inpval.password;
    setCBtn(con);
  }, [inpval]);

  const handleSignin = async (e) => {
    e.preventDefault();
    const { email, password } = inpval;
    setLoading(true);
    await dispatch(loginUser({ email, password })).then((res) => {
      if (res.payload === undefined) {
        setError(res.error.message);
      } else navigate("/");
    });
    setLoading(false);
  };
  const handleGoogleLogin = async () => {
    try {
      const redirectUrl = encodeURIComponent(
        `${window.location.origin}/success`
      );
      window.location.href = `${BACKEND_URL}/auth/google?redirectUrl=${redirectUrl}`;
    } catch (error) {
      console.error("Google login error:", error);
    }
  };
  return (
    <>
      <Helmet>
        <title>Aesthetic Locator</title>
        <meta
          name="description"
          content="Aesthetic Locator"
        />
      </Helmet>
      <div className="min-h-screen bg-gray-100 text-gray-900 flex justify-center items-center">
        <div className="bg-white-A700 p-10 flex flex-col items-center rounded-lg">
          {error && <Warning text={error} showImage={false} />}
          <Img
            src="logo.png"
            alt="imageseven_one"
            className="w-[72%] object-cover"
          />

          <Text size="md" as="p" className="tracking-[0.18px]">
            Log In
          </Text>
          <Button
            leftIcon={<Img src="images/img_icon.svg" alt="↳ 📍icon " />}
            className="w-full gap-1.5 sm:px-5 rounded-lg bg-[#dee4e1] my-4"
            onClick={handleGoogleLogin}
          >
            Continue with google
          </Button>
          <div className="flex sm:flex-col self-stretch justify-center items-center gap-4">
            <div className="sm:self-stretch h-px bg-gray-50 flex-1" />
            <Text
              as="p"
              className="self-start !text-gray-600 tracking-[0.50px]"
            >
              or login in with e-mail
            </Text>
            <div className="sm:self-stretch h-px bg-gray-50 flex-1" />
          </div>
          <CustomInput onChange={setVal} value={inpval.email} name="email" />
          <div className="w-full my-4">

          <PasswordInput
            showForgot={true}
            value={inpval.password}
            name="password"
            onChange={setVal}
            />
            </div>
          <div className="flex flex-col self-stretch items-center gap-[19px]">
            {loading ? (
              <Spinner />
            ) : (
              <Button
              variant="gradient"
                        color="teal_300_teal_600"
                        
                className="w-full sm:px-5 tracking-[1.25px] uppercase font-medium mt-5 rounded-lg"
                onClick={handleSignin}
                disabled={!cBtn}
                >
                Continue
              </Button>
            )}
            <Text as="p" className="flex tracking-[0.50px]">
              <span className="text-gray-900">
                Don’t have an account?&nbsp;
              </span>
              <a href="sign-up" className="text-teal-600 font-bold underline">
                Sign up
              </a>
            </Text>
          </div>
        </div>
      </div>
    </>
  );
}
