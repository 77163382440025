import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import AdminTable from "../../components/AdminTable";
import { Button, Text } from "../../components";
import AdminModal from "components/AdminModal";
import { deleteProduct, getProducts } from "api/admin/categories"; // Assuming `getUsers` is available in your API
import { Spinner } from "flowbite-react";
import { toast } from "react-toastify";
import BulkUpdateModal from "modals/BulkUpdate";
import { useSelector } from "react-redux";
import { getUsers } from "api/admin/users";
import AddCredits from "modals/AddCredits";
import CreateUserModal from "modals/CreateUser";
import { formatTimestamp } from "utils/utils";

export default function Admin() {
  const [activeTab, setActiveTab] = useState("index"); // Track active tab
  const [data, setData] = useState(null);
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);
  const { userObj } = useSelector((state) => state.user);
  const [editData, setEditData] = useState(null);
  const [updateUser, setUpdateUser] = useState(null);
  const [createUser, setCreateUser] = useState(false);
  const [bulkUpdateModal, setBulkUpdateModal] = useState(false);
  const [users, setUsers] = useState([]); // To store users data

  const handleCloseModal = async (refresh) => {
    setEditData(null);
    if (refresh) await loadCategories();
  };

  const handleEditingIndex = (item) => {
    setEditData(item);
  };

  const handleDeletingIndex = async (id) => {
    try {
      const resp = await deleteProduct(id, userObj.token);
      await loadCategories();
      toast.success(resp.message || "Category Successfully Deleted");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error); // Show server error message
      } else {
        toast.error("Unknown error occurred."); // Show generic error message
      }
    }
  };

  const processCategories = (categories) => {
    const result = {
      Brand: [],
      "Product Name": [],
      "Treatment Category": [],
      "Treatment Keywords": [],
    };

    categories.forEach((item) => {
      // Add unique brands
      if (!result["Brand"].includes(item.brand)) {
        result["Brand"].push(item.brand);
      }

      // Add unique product names
      if (!result["Product Name"].includes(item.product_name)) {
        result["Product Name"].push(item.product_name);
      }

      // Add unique treatment categories
      if (!result["Treatment Category"].includes(item.treatment_category)) {
        result["Treatment Category"].push(item.treatment_category);
      }

      // Add unique treatment keywords
      item.treatment_keywords.forEach((keyword) => {
        if (!result["Treatment Keywords"].includes(keyword)) {
          result["Treatment Keywords"].push(keyword);
        }
      });
    });

    return result;
  };

  const loadCategories = async () => {
    setLoading(true);
    try {
      const resp = await getProducts(userObj.token);
      setData(resp.products);
      const l = processCategories(resp.products);
      setProducts(l);
    } catch (err) {
      toast.error("Error fetching categories");
      console.log(err);
    }
    setLoading(false);
  };

  const loadUsers = async () => {
    setLoading(true);
    try {
      const resp = await getUsers(userObj.token); // Fetch users list
      setUsers(resp); // Assuming the response contains `users` array
    } catch (err) {
      toast.error("Error fetching users");
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (activeTab === "index") {
      loadCategories(); // Load categories when on the "Index" tab
    } else if (activeTab === "users") {
      loadUsers(); // Load users when on the "Users" tab
    }
  }, [userObj, activeTab]);

  return (
    <>
      <Helmet>
        <title>Aesthetic Locator</title>
        <meta name="description" content="Aesthetic Locator" />
      </Helmet>
      <div className="flex flex-col w-full gap-4 p-4 bg-gray-50 h-full">
        <Header />
        <div className="w-full mx-auto max-w-[1856px]">
          {/* Tabs */}
          <div className="flex justify-center gap-8 mb-4">
            <div
              className={`${
                activeTab == "index" ? "!text-teal-600" : "!text-black-200"
              } cursor-pointer`}
              onClick={() => setActiveTab("index")}
            >
              Index
            </div>
            <div
              className={`${
                activeTab == "users" ? "!text-teal-600" : "!text-black-200"
              } cursor-pointer`}
              onClick={() => setActiveTab("users")}
            >
              Users
            </div>
          </div>

          <div className="flex justify-center h-[80vh]">
            {loading ? (
              <Spinner />
            ) : activeTab === "index" ? (
              // Index Tab Content
              <div className="w-full flex flex-col p-4 bg-white-A700 rounded-[16px] gap-4">
                <div className="flex w-full justify-between items-center">
                  <Text size="lg" as="p" className="tracking-[0.18px]">
                    <span className="text-black-900_01">
                      Total Results&nbsp;
                    </span>
                    <span className="text-teal-300">{data?.length || 0}</span>
                  </Text>
                  <div className="flex gap-2">
                    <Button
                      variant="gradient"
                      shape="round"
                      color="teal_300_teal_600"
                      className="sm:px-5 tracking-[1.25px] uppercase font-medium rounded-md"
                      onClick={() => setBulkUpdateModal(true)}
                    >
                      Bulk Update
                    </Button>
                    <Button
                      variant="gradient"
                      shape="round"
                      color="teal_300_teal_600"
                      className="sm:px-5 tracking-[1.25px] uppercase font-medium rounded-md"
                      onClick={() => setEditData({})}
                    >
                      Create
                    </Button>
                  </div>
                </div>
                <AdminTable
                  dataList={data}
                  onEditingIndex={handleEditingIndex}
                  onDeletingIndex={handleDeletingIndex}
                />
              </div>
            ) : (
              // Users Tab Content
              <div className="w-full flex flex-col p-4 bg-white-A700 rounded-[16px] gap-4">
                <div className="flex items-center justify-between">
                  <Text size="lg" as="p" className="tracking-[0.18px]">
                    <span className="text-black-900_01">Total Users&nbsp;</span>
                    <span className="text-teal-300">{users.length || 0}</span>
                  </Text>

                  <Button
                    variant="gradient"
                    color="teal_300_teal_600"
                    className="sm:px-5 tracking-[1.25px] uppercase font-medium rounded-lg"
                    onClick={() => setCreateUser(true)}
                  >
                    Create User
                  </Button>
                </div>
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="border max-h-[65vh] overflow-auto">
                    <table className="min-w-full divide-y divide-gray-400 dark:divide-gray-700">
                      <thead className="bg-gray-50 dark:bg-gray-800 sticky top-0">
                        <tr>
                          {["", "Name", "Email","Customer Id", "Plan Id","Credits", "Date&Time"].map(
                            (header, index) => (
                              <th
                                key={index}
                                scope="col"
                                className="font-bold px-4 py-3.5 text-md  rtl:text-right text-gray-700 dark:text-gray-400"
                              >
                                {header}
                              </th>
                            )
                          )}
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-300 dark:divide-gray-700 dark:bg-gray-900">
                        {users.map((item, index) => {
                          return (
                            <tr
                              key={index}
                              className="hover:bg-gray-100 cursor-pointer"
                              // onClick={() => {
                              // handleShowMore(item);
                              // }}
                              onClick={()=>setUpdateUser(item)}
                            >
                              <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 font-bold">
                                {index + 1}
                              </td>
                              <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 text-center">
                                {item.name}
                              </td>
                              <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 text-center">
                                {item.email}
                              </td>
                         
                              <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 text-center">
                                  {item?.stripeCustomerId}
                                </td>
                                <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 text-center">
                                  {item?.planId}
                                </td>
                                <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 text-center">
                                  {item?.credits}
                                </td>
                                <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 text-center">
                                {item?.createdAt
                                  ? formatTimestamp(item.createdAt)
                                  : "N/A"}
                              </td>
                           
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modals */}
      <AdminModal
        isShowModal={editData !== null}
        modalData={editData}
        onModalClose={handleCloseModal}
        token={userObj.token}
      />
      {updateUser && (
        <AddCredits
          isShowModal={updateUser !== null}
          modalData={updateUser}
          onModalClose={() => setUpdateUser(null)}
          handleUpdate={() => {
            setUpdateUser(null);
            loadUsers();
          }}
          token={userObj.token}
        />
      )}
      <CreateUserModal
        isShowModal={createUser}
        onModalClose={(val) => {
          setCreateUser(false);
          if (val) {
            loadUsers();
          }
        }}
        token={userObj.token}
      />
      <BulkUpdateModal
        isShowModal={bulkUpdateModal}
        handleRefresh={loadCategories}
        products={products}
        onModalClose={() => setBulkUpdateModal(false)}
        token={userObj.token}
      />
    </>
  );
}
