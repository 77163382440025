import React, { useEffect, useRef, useState } from "react";
import { Img, Heading, Text, Button } from "./..";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearUser } from "store/slices/UserSlice";
import { getUser } from "api/auth";
export default function Header({ ...props }) {
  const { userObj } = useSelector((state) => state.user);
  const [credits, setCredits] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const PAGES = [
    { title: "Home", urls: ["/"] },
    { title: "Results", urls: ["/results"] },
  ];
  const handleLogout = () => {
    dispatch(clearUser());
    navigate("/sign-in");
  };
  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle menu visibility on click
  };
  const handleMenuItemClick = (item) => {
    if (item === "logout") handleLogout();
    else if (item === "settings") navigate("/settings");
    else if (item === "admin") navigate("/admin");
    setIsMenuOpen(false);
  };
  const catMenu = useRef(null);
  const closeOpenMenus = (e) => {
    if (isMenuOpen && !catMenu.current?.contains(e.target)) {
      setIsMenuOpen(false);
    }
  };

  document.addEventListener("mousedown", closeOpenMenus);
  const menuItems = [
    {
      key: "logout",
      icon: "images/img_settings.svg",
      alt: "settings",
      label: "Logout",
    },
    {
      key: "settings",
      icon: "images/img_logout.svg",
      alt: "settings",
      label: "Settings",
    },
  ];

  // Add admin item conditionally
  if (isAdmin) {
    menuItems.push({
      key: "admin",
      icon: "images/img_admin_panel.svg",
      alt: "admin panel",
      label: "Admin",
    });
  }

  const getCredits = async (token) => {
    try {
      const data = await getUser(token);
      const credits = data?.credits || 0;
      if (data?.admin) {
        setIsAdmin(true); // Set admin state
      }
      setCredits(credits);
    } catch (error) {
      console.error("Error verifying token with backend:", error);
      return false;
    }
  };

  useEffect(() => {
    const token = userObj?.token || undefined;
    if (token) {
      getCredits(token);
    }
  }, [userObj]);
  return (
    <header className="py-2 bg-white-A700 rounded-[16px]">
      <div className="w-full mx-auto" ref={catMenu}>
        <div className="flex justify-between items-center gap-5">
          <Img
            src="logo.png"
            alt="imageseven_one"
            className="w-[20%]  object-cover cursor-pointer"
            onClick={() => navigate("/")}
          />
          <div className="w-full sm:hidden">
            {userObj ? (
              <div className="flex">
                <div className="flex w-[70%] justify-center md:w-[60%]">
                  {PAGES.map((p, i) =>
                    p.urls.indexOf(location.pathname) !== -1 ? (
                      <div className="flex flex-col items-center gap-2" key={i}>
                        <Text
                          as="p"
                          className="mt-[9px] !text-teal-300 tracking-[0.50px]"
                        >
                          {p.title}
                        </Text>
                      </div>
                    ) : (
                      <div
                        className="flex p-2.5 cursor-pointer"
                        onClick={() => navigate(p.urls[0])}
                        key={i}
                      >
                        <div className="flex">
                          <Text as="p" className="self-start tracking-[0.50px]">
                            {p.title}
                          </Text>
                        </div>
                      </div>
                    )
                  )}
                </div>
                <div
                  className="flex justify-center items-center w-1/4  gap-1 hover:bg-gray-100 rounded-lg cursor-pointer"
                  onClick={handleMenuClick}
                >
                  <Img
                    src="images/user.png"
                    alt="circleimage"
                    className="h-[40px] w-[40px] rounded-[50%]"
                  />
                  <div className="flex flex-col items-center">
                    <Heading
                      size="s"
                      as="h6"
                      className="self-start !text-gray-800 text-center font-light"
                    >
                      {userObj.email}
                      {/* {userObj?.name.split(" ")[0] || "User"} */}
                    </Heading>
                    {credits !== null && (
                      <div className="flex items-center">
                        <div className="flex gap-1">
                          <Heading className="!text-gray-800 font-light">
                            Credits{" "}
                          </Heading>
                          <Heading className="!text-gray-500">
                            {credits}
                          </Heading>
                        </div>
                        <Img
                          src="images/img_coins.svg"
                          alt="phwarning_one"
                          className="h-[20px] w-[20px] md:w-full"
                        />
                      </div>
                    )}
                    {isMenuOpen && (
                      <div
                        className={
                          "absolute top-[14%] right-10 bg-gray-100 w-[150px] rounded shadow-lg z-50"
                        }
                      >
                        <ul className="list-none p-2 text-left">
                          {menuItems.map((item, index) => (
                            <React.Fragment key={item.key}>
                              <li
                                className="flex items-center hover:bg-gray-200 p-2 mt-1 cursor-pointer"
                                onClick={() => handleMenuItemClick(item.key)}
                              >
                                <Img
                                  src={item.icon}
                                  alt={item.alt}
                                  className="h-[24px] w-[24px] mr-2"
                                />
                                {item.label}
                              </li>
                              {index < menuItems.length - 1 && (
                                <li className="border-gray-300 h-2 border-b" />
                              )}
                            </React.Fragment>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <Button
                variant="gradient"
                color="teal_300_teal_600"
                className="sm:px-5 tracking-[1.25px] uppercase font-medium rounded-lg"
                onClick={() => navigate("/sign-up")}
              >
                Sign Up
              </Button>
            )}
          </div>
          <Img
            src="images/img-burger.svg"
            alt="imageseven_one"
            className="w-[10%] hidden sm:flex"
            onClick={() => setIsMobileMenuOpen(true)}
          />
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className="navbar-menu relative z-50">
          <nav className="fixed top-0 left-0 bottom-0 flex flex-col  py-6 px-6 border-r overflow-y-auto bg-white-A700">
            <div className="flex items-center mb-8 justify-between">
              <Img
                src="logo.png"
                alt="imageseven_one"
                className="w-[40%]  object-cover"
              />
              <Img
                src="images/img_close_teal_300.svg"
                alt="close"
                onClick={() => setIsMobileMenuOpen(false)}
              />
            </div>
            <div>
              <ul>
                {PAGES.map((p, i) => (
                  <li className="mb-1" key={i}>
                    {" "}
                    {/* Added key prop for uniqueness */}
                    <a
                      className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded"
                      href={p.urls[0]} // Assuming the first URL in urls should be used
                    >
                      {p.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <Button className="bg-teal-300 rounded-md" onClick={handleLogout}>
              Logout
            </Button>
          </nav>
        </div>
      )}
    </header>
  );
}
