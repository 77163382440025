import { Button, Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";

export default function DeleteModal({openModal, onCloseModal, onConfirmModal}) {
  return (
    <Modal show={openModal} size="md" onClose={() => onCloseModal(false)} popup>
      <Modal.Header className="bg-white-A700" />
      <Modal.Body className="bg-white-A700">
        <div className="text-center">
          <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            Are you sure you want to delete this product?
          </h3>
          <div className="flex justify-center gap-4">
            <Button color="failure" onClick={() =>onConfirmModal() }>
              {"Yes, I'm sure"}
            </Button>
            <Button color="gray" onClick={() => onCloseModal(false)}>
              No, cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}