import axios from "axios";
import { BACKEND_URL } from "../../config/config";

const VERSION = 'v1';

// Get products with token
const getProducts = async (token) => {
  try {
    const response = await axios.get(`${BACKEND_URL}/admin/api/${VERSION}/products?token=${token}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

// Delete product with token
const deleteProduct = async (id, token) => {
  try {
    const response = await axios.delete(`${BACKEND_URL}/admin/api/${VERSION}/product/${id}?token=${token}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting product with ID ${id}:`, error);
    throw error;
  }
};

// Create product with token
const createProduct = async (productData, token) => {
  try {
    const response = await axios.post(`${BACKEND_URL}/admin/api/${VERSION}/product?token=${token}`, productData);
    return response.data;
  } catch (error) {
    console.error("Error creating product:", error);
    throw error;
  }
};

// Bulk update products with token
const bulkUpdateProducts = async (productData, token) => {
  try {
    const response = await axios.put(`${BACKEND_URL}/admin/api/${VERSION}/products/bulk-update?token=${token}`, productData);
    return response.data;
  } catch (error) {
    console.error("Error bulk updating products:", error);
    throw error;
  }
};

// Update product with token
const updateProduct = async (id, updatedData, token) => {
  try {
    const response = await axios.put(`${BACKEND_URL}/admin/api/${VERSION}/product/${id}?token=${token}`, updatedData);
    return response.data;
  } catch (error) {
    console.error(`Error updating product with ID ${id}:`, error);
    throw error;
  }
};

export { getProducts, deleteProduct, createProduct, updateProduct, bulkUpdateProducts };
