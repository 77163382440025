import axios from "axios";
import { BACKEND_URL } from "../config/config";

const VERSION = 'v1';


const updateUser = async (token,data) => {
  try {
    const response = await axios.put(`${BACKEND_URL}/api/${VERSION}/user?token=${token}`, data);

    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};



export { updateUser };
