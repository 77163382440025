import axios from "axios";
import { BACKEND_URL } from "../config/config";

const VERSION = "v1";

const getExports = async (token) => {
  try {
    const response = await axios.get(`${BACKEND_URL}/api/${VERSION}/exports?token=${token}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching searched:", error);
    throw error;
  }
};

const getSearches = async () => {
  try {
    const response = await axios.get(`${BACKEND_URL}/api/${VERSION}/searches`);
    return response.data;
  } catch (error) {
    console.error("Error fetching searched:", error);
    throw error;
  }
};

const deleteSearch = async (id) => {
  try {
    const response = await axios.delete(
      `${BACKEND_URL}/api/${VERSION}/search/${id}`
    );
    return response.data;
  } catch (error) {
    console.error(`Error deleting search with ID ${id}:`, error);
    throw error;
  }
};

const createSearch = async (searchData) => {
  try {
    const response = await axios.post(
      `${BACKEND_URL}/api/${VERSION}/search`,
      searchData
    );
    return response.data;
  } catch (error) {
    console.error("Error creating search:", error);
    throw error;
  }
};

export { getSearches, createSearch, deleteSearch,getExports };
