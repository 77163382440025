import React, { useEffect, useState } from "react";
import { Modal, Button, Label, Radio } from "flowbite-react";
import { Input } from "components/Input";
import MultipleInput from "components/MultiInput";
import { toast } from "react-toastify";
import { createProduct, updateProduct } from "api/admin/categories";
import MultipleSelectInput from "components/MultiSelectInput";
const OPTIONS = require("../../config/options.json");
export default function AdminModal({ isShowModal, modalData, onModalClose,token }) {
	const [data, setData] = useState({
		brand: "",
		product_name: "",
		treatment_category: "",
		treatment_keywords: [],
		device_type: "Devices",
	});

	useEffect(() => {
		if (modalData) {
			setData({
				brand: modalData?.brand || "",
				product_name: modalData?.product_name || "",
				treatment_category: modalData?.treatment_category || "",
				treatment_keywords: modalData?.treatment_keywords || [],
				device_type: modalData?.device_type || "Devices",
			});
		}
	}, [modalData]);

	const handleSave = async () => {
		if (
			!data.brand ||
			!data.product_name ||
			!data.device_type ||
			!data.treatment_category
		) {
			toast.error("Enter full details to create");
			return;
		}
		try {
			const resp = await createProduct(data,token);
			toast.success(resp.message || "Category Successfully Created");
			setTimeout(() => {
				onModalClose(true);
			}, 500);
		} catch (error) {
			if (error.response && error.response.data && error.response.data.error) {
				toast.error(error.response.data.error); // Show server error message
			} else {
				toast.error("Unknown error occurred."); // Show generic error message
			}
		}
	};

	const handleUpdate = async () => {
		if (
			!data.brand ||
			!data.product_name ||
			!data.device_type ||
			!data.treatment_category
		) {
			toast.error("Enter full details to update");
			return;
		}
		try {
			const resp = await updateProduct(modalData._id,data,token);
			toast.success(resp.message || "Category Successfully Updated");
			setTimeout(() => {
				onModalClose(true);
			}, 500);
		} catch (error) {
			if (error.response && error.response.data && error.response.data.error) {
				toast.error(error.response.data.error); // Show server error message
			} else {
				toast.error("Unknown error occurred."); // Show generic error message
			}
		}
	};

	function handleMultiGet(txt) {
		if (txt) return [txt];
		return [];
	}
	function handleMultiSet(ls) {
		if (ls && ls.length > 0) return ls[0];
		return "";
	}

	const isEdit = modalData?.brand === undefined ? false : true;
	return (
		<Modal show={isShowModal} onClose={() => onModalClose(false)}>
			<Modal.Header className="bg-white-A700">
				{isEdit ? "Edit" : "Create"}
			</Modal.Header>
			<Modal.Body className="bg-white-A700 px-10 max-h-[1200px]">
				<div className="space-y-6">
					<MultipleSelectInput
						options={OPTIONS["brands"]}
						label="Brand Name"
						placeholder="Select Brand"
						selected={handleMultiGet(data.brand)}
						setSelected={(brand) => {
							setData({ ...data, brand: handleMultiSet(brand) });
						}}
						customInput={true}
						multiselect={false}
					/>
					<MultipleSelectInput
						options={OPTIONS["products"]}
						label="Product Name"
						placeholder="Select Product"
						selected={handleMultiGet(data.product_name)}
						setSelected={(product_name) => {
							setData({ ...data, product_name: handleMultiSet(product_name) });
						}}
						customInput={true}
						multiselect={false}
					/>
					<MultipleSelectInput
						options={OPTIONS["categories"]}
						label="Treatment Category"
						placeholder="Select Treatment Category"
						selected={handleMultiGet(data.treatment_category)}
						setSelected={(treatment_category) => {
							setData({
								...data,
								treatment_category: handleMultiSet(treatment_category),
							});
						}}
						customInput={true}
						multiselect={false}
					/>
					<MultipleSelectInput
						options={data.treatment_keywords}
						label="Treatment Keywords"
						placeholder="Select Treatment Keywords"
						selected={data.treatment_keywords}
						setSelected={(treatment_keywords) =>
							setData({ ...data, treatment_keywords })
						}
						customInput={true}
					/>
					<fieldset className="flex gap-2 justify-end">
						<div className="flex items-center gap-2">
							<Radio
								id="injectable"
								name="type"
								value="Injectables"
								checked={data.device_type === "Injectables"}
								onChange={(event) => {
									setData({ ...data, device_type: event.target.value });
								}}
							/>
							<Label htmlFor="injectable">Injectable</Label>
						</div>
						<div className="flex items-center gap-2">
							<Radio
								id="devices"
								name="type"
								value="Devices"
								checked={data.device_type === "Devices"}
								onChange={(event) =>
									setData({ ...data, device_type: event.target.value })
								}
							/>
							<Label htmlFor="devices">Device</Label>
						</div>
					</fieldset>
				</div>
			</Modal.Body>
			<Modal.Footer className="bg-white-A700">
				<div className="flex w-full justify-end gap-4">
					<Button color="gray" onClick={() => onModalClose(false)}>
						Cancel
					</Button>
					<Button onClick={isEdit?handleUpdate:handleSave}>Save</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
}
